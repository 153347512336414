/* eslint-disable no-restricted-globals */
// import { pick } from 'ramda'
import { renderSlim } from '@aspectus/vue-utils';

import {
  urlGetter,
} from '@aspectus/vue-friendly'

const same = x => x;
export default {
  name: 'url-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
    baseUrl: {},
    dropPageUrl: {
      type: Boolean,
    },
  },

  data() {
    return {
      parameters: this.initial,
      friendlyUrl: {
        filters: {},
      },
    };
  },
  mounted() {
    window.addEventListener('popstate', () => {
      this.parameters = this.from({
        ...this.initial,
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.parameters = this.from({
          ...this.initial,
        });
      },
    },
  },

  methods: {
    updateUrl(parameters) {
      const query = this.to(parameters)
      const zero = 0
      this.friendlyUrl.filters = query.filters
      const keys = Object.keys(this.friendlyUrl.filters)
      for (let x = 0; x <= keys.length; x++) {
        if (this.friendlyUrl.filters[keys[x]] !== undefined) {
          if (zero === this.friendlyUrl.filters[keys[x]].length) {
            delete this.friendlyUrl.filters[keys[x]]
          }
        }
      }
      const order = {
        path: [
          'prefix',
          'filters',
          'page',
        ],
      }
      const shift = 1
      const currentPage = Math
        .floor(parameters.offset / parameters.limit) + shift
      const page = currentPage > shift ? currentPage : null
      const base = Object.keys(parameters.filters).length ? `${this.baseUrl}filters` : this.baseUrl
      const fullUrl = urlGetter([base], order, { ...parameters, page })
      history.pushState({ url: fullUrl }, '', `${fullUrl}${window.location.search}`)
    },

    changeParameters(value) {
      this.parameters = value;
      this.updateUrl(this.parameters);
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      parameters: this.parameters,
      changeParameters: this.changeParameters,
      updateUrl: this.updateUrl,
    }), h, 'tag');
  },
};
