<script>

import { topicDeleteResource } from '@api/forum.service.js'
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin'
import { modalOpener } from '@utils/helpers';

const confirmModal = () => import('../Modals/Interaction-modals/ConfirmModal');

export default {
  name: 'delete-forum-topic',
  mixins: [LoadingStateMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      error: null,
    }
  },
  methods: {
    modalOpener,
    confirmModalConfig(notifyId) {
      this.modalOpener({
        props: {
          isComponent: confirmModal,

        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.deleteTopic,
        value: notifyId,
      })
    },
    deleteTopic() {
      this.$load(topicDeleteResource.execute({ id: this.id }))
        .then(result => {
          if (204 === result.status) {
            window.location.pathname = window.CONFIG.FORUM_PAGE_URL
          }
          if (400 === result.status) {
            result.json().then(({ errors }) => {
              this.error = errors[0].state[0].message
            })
          }
        })
    },
  },
  render() {
    return this.$scopedSlots.default({
      deleteTopic: this.confirmModalConfig,
      error: this.error,
      loading: this.loading,
    })
  },
}
</script>
