<template lang="pug">
include /mixins
+b.DIV
  click-outside(:do='shareClose')
    +b.video__share(@click="shareHiden = !shareHiden")
      +e.share-text {{ _('Поделиться') }}
      +e.share-icon.icon-share
      transition(
        name="slide-fade"
      )
        +b.social-nav.--absolute(v-if="!shareHiden")
          +e.UL.list
            +e.LI.item
              +e.P.social-link.fas.fa-link(
                @click.prevent="copyToClipboard()"
              )
            +e.LI.item
              share-network(
                network="facebook"
                :url="shareContent"
                :title="title"
                :description="description"
                :quote="quote"
                :hashtags="hashtags"
              )
                +e.social-link.icon-fb
            +e.LI.item
              share-network(
                network="twitter"
                :url="shareContent"
                :title="title"
                :description="description"
                :quote="quote"
                :hashtags="hashtags"
              )
                +e.social-link.icon-twitter
            +e.LI.item
              share-network(
                network="vk"
                :url="shareContent"
                :title="title"
                :description="description"
                :quote="quote"
                :hashtags="hashtags"
              )
                +e.social-link.--vk.icon-vk
            //- +e.LI.item
            //-   +e.A.social-link.icon-google(
            //-     href="https://plus.google.com/share?url=//www.w3docs.com/learn-javascript/google-share.html"
            //-     onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,height=600,width=600');return false;"
            //-   )
</template>

<script>
export default {
  name: 'SetLikes',
  props: ['shareContent', 'title', 'description', 'quote', 'hashtags'],
  data() {
    return {
      likes: '',
      hasLike: false,
      shareHiden: true,
      toastText: this._('Ссылка скопирована в буфер обмена'),
    };
  },
  methods: {
    shareClose() {
      this.shareHiden = true
    },
    copyToClipboard() {
      this.$copyToClipboard(this.shareContent)
      this.$toasted.show(this.toastText, {
        theme: 'outline',
        position: 'top-left',
        duration: 2500,
      });
    },
  },
  mounted() {
  },
};
</script>
