import { createResource, postResource } from '@resource/resource';

import { prefixAPI } from '@resource/api';
// import {
//   simpleTransformer,
// } from '@resource/transformer'

const LOGOUT_URL = prefixAPI('auth/logout/');
const LOGIN_URL = prefixAPI('auth/login/');
const REGISTER_URL = prefixAPI('auth/register/');
const REGISTER_CONFIRM = prefixAPI('auth/register/confirm/')
const PASS_RECOVERY_URL = prefixAPI('auth/password-reset/')
const PASS_RECOVERY_SET_URL = prefixAPI('auth/password-reset/set/')
// const JOIN_US_URL = prefixAPI("communication/join/");
// const SUBSCRIBE = prefixAPI("communication/subscribe/");
// const FAQ = prefixAPI("faq/");
// const CONTACT = prefixAPI("communication/contact/");
export const passRecoverySet = createResource(PASS_RECOVERY_SET_URL, postResource);
export const passRecoveryResource = createResource(PASS_RECOVERY_URL, postResource);
export const loginResource = createResource(LOGIN_URL, postResource);
export const registerResource = createResource(REGISTER_URL, postResource);
export const registerConfirm = createResource(REGISTER_CONFIRM, postResource);
export const logoutResource = createResource(LOGOUT_URL, postResource);

// export const sendJoinUs = createResource(JOIN_US_URL, postResource);
// export const subscribe = createResource(SUBSCRIBE, postResource);
// export const faq = createResource(FAQ);
// export const contact = createResource(CONTACT, postResource);
