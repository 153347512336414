<template lang="pug">
  include /mixins
  +b.SPAN.to-cabinet-link(
    @click.prevent="toCabinet()"
    )
    slot
</template>

<script>
export default {
  props: ['title', 'url'],
  name: 'ToCabinet',
  data() {
    return {
    };
  },
  methods: {
    toCabinet() {
      window.location.pathname = this.url
    },
  },
};
</script>
