<template lang="pug">
include /mixins
+b.DIV.js-train(
  :data-active='_("Записаться")'
  :data-inactive='_("Отменить запись")'
  :data-past='_("Завершена")'
)
  +b.BUTTON.training__button.btn.btn-red.js-signup(
    v-if="!isSignUp"
    @click.prevent="openCurrentModal()"
  ) {{_('Записаться')}}
  +b.BUTTON.trainers__button.btn.btn-dark-trans.js-signup(
    v-if="isSignUp"
    @click.prevent="openCurrentModal()"
  ) {{_('Отменить запись')}}
</template>

<script>

import { modalOpener } from '@utils/helpers'

const TrainingSignUp = () => import('../Interaction-modals/TrainingSignUp');
const BuySubscription = () => import('../Interaction-modals/BuySubscription');
const AuthModal = () => import('../Auth/Login');

export default {
  name: 'TrainingJoinBtn',
  props: ['info', 'isSign'],
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      haveAccess: '',
      infoData: '',
      isSignUp: false,
      AuthModalConfig: {
        props: {
          isComponent: AuthModal,
        },
        // classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '345',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      TrainingSignUpModal: {
        props: {
          isComponent: TrainingSignUp,
          info: '',
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.signHandler,
      },
      buyModalConfig: {
        props: {
          isComponent: BuySubscription,
          title: this._('Для того, чтобы записаться на данную тренировку, улучшите свой абонемент'),
          variables: {
            theme: 'dark',
            isAuth: false,
          },
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: 593,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.openAuthModal,
      },
    };
  },
  mounted() {
    this.TrainingSignUpModal.props.info = this?.info
    this.isSignUp = this.info?.signUp
    this.access = this.info?.haveAccess
  },
  methods: {
    modalOpener,
    openCurrentModal() {
      if (this.isAuth) {
        if (this.haveAccess || this.info?.haveAccess) {
          this.modalOpener(this.TrainingSignUpModal)
        } else {
          this.modalOpener(this.buyModalConfig)
        }
      } else {
        this.modalOpener(this.AuthModalConfig)
      }
    },
    signHandler(data) {
      this.TrainingSignUpModal.props.info.signUp = data.bool
      this.isSignUp = data.bool
      this.$emit('finish')
    },
  },
};
</script>
