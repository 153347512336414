<template lang="pug">
include /mixins
+b.modal-wrapper(
    class="modal-wrapper--theme_dark"
  )
  +b.ds-panel.--space_xl
    +e.element.--offset_full
      +b.g-row.--space_lg.--justify_center
        +b.g-cell.g-cols--12
          +b.H2.auth-form__title.--offset_none(v-if="!info.signUp") {{ _("Записаться на тренировку?") }}
          +b.H2.auth-form__title.--offset_none(v-if="info.signUp") {{ _("Отменить запись?") }}
        +b.g-cell.g-cols--narrow
          +b.BUTTON.btn.btn-red(
            @click.prevent="joinTraining()"
          ) {{ _("Да") }}
        +b.g-cell.g-cols--narrow
          +b.BUTTON.btn.btn-black(
            @click.prevent="$emit('close')"
          ) {{ _("Нет") }}

</template>

<script>
import {
  trainingsJoin,
} from '@api/trainings.service.js';
import { modalOpener } from '@utils/helpers'

const Login = () => import('../Auth/Login')
const JoinSuccess = () => import('../Interaction-modals/TrainingJoinSuccess');

export default {
  name: 'TrainingSignUp',
  props: ['info'],
  data() {
    return {
      isSignUp: false,
      authModal: {
        props: {
          isComponent: Login,
          info: '',
        },
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      signOutSuccesModal: {
        props: {
          title: this._('Ваша запись отменена!'),
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '593',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
      succesModal: {
        props: {
          isComponent: JoinSuccess,
          info: this.info,
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '593',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
    };
  },
  mounted() {
    // this.succesModal.props.info = this.info
    // if ('True' === this.info.singUp) {
    //   this.isSignUp = true
    // }
  },
  methods: {
    modalOpener,
    joinTraining() {
      const trainingId = this.info.id
      return trainingsJoin.execute({ id: trainingId }).then(res => {
        if (200 === res.code) {
          if (res.data.item.isTrainingparticipant) {
            this.modalOpener(this.succesModal)
          } else {
            this.modalOpener(this.signOutSuccesModal)
          }
          const resp = { bool: res.data.item.isTrainingparticipant, data: this.info }
          this.$emit('finish', resp);
          this.close()
        }
      })
    },
    updateSubscription() {
      if (!this.$attrs.variables.isAuth) {
        this.modalOpener(this.authModal)
        this.close()
      } else {
        window.location.pathname = '/subscription/'
      }
    },
    close() {
      this.$emit('close')
    },
  },
};
</script>
