/* eslint-disable */
import { MessageComponentMixin } from './mixins';
import DEFAULT_MESSAGE_TYPE_HANDLERS from './types';

/**
 * Displays message of any type.
 *
 * For all types of messages, that might be displayed there must be
 * provided `chatMessagingMessageTypeHandlers` provider, or default
 * handlers will be used.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 */
export default {
  name: 'WdsChatMessage',
  mixins: [MessageComponentMixin],

  bem: {
    block: 'wds-chat-bubble',
    modifierProps: ['alignment', 'devoted', 'type'],
  },

  types: {
    default: 'text',
    handlers: DEFAULT_MESSAGE_TYPE_HANDLERS,
  },

  inject: {
    messageTypeHandlers: {
      from: 'chatMessagingMessageTypeHandlers',
      default: DEFAULT_MESSAGE_TYPE_HANDLERS,
    },
  },

  computed: {
    component() {
      return (
        this.messageTypeHandlers[this.message.type] ||
        this.messageTypeHandlers[this.types.default]
      ).component;
    },
  },
}
