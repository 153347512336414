<template lang='pug'>
  div#content-parent
    renderer(
      :result='items && items',
      v-if='items && items'
    )
    slot(v-else)
    ui-limit-pagination(
      :pagination='pagination'
      @input='handlePaginate'
    )
    //- ui-loader(v-if='loading')
</template>

<script>
const Renderer = () => import('./Renderer');

const UiLimitPagination = () => import('@app/Pagination/UiLimitPagination');

export default {
  props: ['loading', 'items', 'pagination'],
  components: {
    Renderer,
    UiLimitPagination,
  },
  methods: {
    handlePaginate({ offset }) {
      if (this.loading) {
        return;
      }
      const init = 0;
      const { limit = init } = this.pagination;
      this.$emit('update:pagination', { limit, offset });
    },
  },
};

</script>
