import * as rules from 'vee-validate/dist/rules';
import trans from '@aspectus/vee-i18n';

import VueTrans, { compileMessages } from '@aspectus/vue-trans';

const NUMERIC = 'numeric';
const LONG = 'long';
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
};
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit',
};
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
};
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
};

const dateTimeFormats = {
  [window.language]: {
    shortDate: SHORT_DATE_CONFIG,
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    datetime: DATETIME_CONFIG,
  },
};
export const i18nDateTimeFormats = {
  shortDate: SHORT_DATE_CONFIG,
  date: DATE_CONFIG,
  time: TIME_CONFIG,
  month: { year: NUMERIC, month: LONG },
  datetime: DATETIME_CONFIG,
};

const SHORT = { notation: 'compact', compactDisplay: 'short' };
const SHORT_UNIT = { ...SHORT, style: 'unit' };
const u = unit => ({ ...SHORT_UNIT, unit });

const numberFormats = {
  [window.language]: {
    liter: u('liter'),
    short: SHORT,
    kilogram: u('kilogram'),
    degree: u('degree'),
  },
};

export function createI18n({ Vue, I18n }) {
  Vue.use(VueTrans);

  const i18n = new I18n({
    ...compileMessages(),
    dateTimeFormats,
    numberFormats,
    silentTranslationWarn: true,
  });

  trans(i18n, rules);

  return i18n;
}
