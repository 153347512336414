/* eslint-disable */
const messageGetter = x => x.message

// export function defaultValidatorErrorsParser(errors) {
//   console.log(errors)
//   return Object.keys(errors).reduce((acc, x) => {
//     acc[x] = errors[x].map(messageGetter)
//     return acc
//   }, {})
// }
export function defaultValidatorErrorsParser(errors, key, accumulator = {}) {
  return Object.keys(errors).reduce((acc, x) => {
    if (Array.isArray(errors[x])) {
      if (errors[x].map(messageGetter)[0]) {
        acc[key ? `${key}:${x}` : x] = errors[x].map(messageGetter);
        return acc;
      }
      errors[x].forEach((el, i) => defaultValidatorErrorsParser(el, key ? `${key}:${x}:${i}` : `${x}:${i}`, acc), acc);
      return acc;
    }
    return defaultValidatorErrorsParser(errors[x], key ? `${key}:${x}` : x, acc);
  }, accumulator);
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
    }
  },
  mounted() {
    // if (this.$refs.validator && this.$refs.validator.$el) {
    //   this.$refs.validator.$el.addEventListener('blur', () => {
    //     if (this.$refs.validator) this.$refs.validator.reset();
    //     this.nonFieldErrors = '';
    //   }, true);
    // }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }

        if (!this.updateValidator) {
          return e
        }

        return e.json().then(body => {
          const errors = {}
          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          this.updateValidator(errors, body.errors)
          return e
        })
      })
    },
    submit(valid, data) {
      if (!valid) {
        return Promise.reject()
      }
      this.isLoad = true
      return this.catchFormErrors(this.send(data))
        .finally(() => {
          this.isLoad = false
        })
    },
  },
}
