import { createRouter } from '@/router';
import { createI18n, i18nDateTimeFormats } from '@/i18n';

export function createApp({ Vue, I18n, Router }) {
  const i18n = createI18n({ Vue, I18n })
  const router = createRouter({ Vue, Router });
  Vue.prototype.$dateTimeFormats = i18nDateTimeFormats;
  Vue.prototype.$d = (date, format) => new Intl.DateTimeFormat('ru', i18nDateTimeFormats[format]).format(date);

  Vue.prototype.$copyToClipboard = function copyToClipboard(value) {
    // HTTPS ок localhost
    navigator.clipboard.writeText(value)
  };

  Vue.prototype.$body = document.body
  const app = new Vue({
    i18n,
    router,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })

  return { app, i18n }
}
