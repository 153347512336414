import { createResource, deleteResource, postResource } from '@resource/resource';
import { prefixAPI } from '@resource/api';
import {
  simpleTransformer,
} from '@resource/transformer'

const COMMENT_CREATE_URL = prefixAPI('comment/create/');
const COMMENT_DELETE_URL = prefixAPI('comment/delete/{id}/')
const GET_COMMENTS_LIST_URL = prefixAPI('comment/list/{object_id}/{obj_content_type}/{?limit,offset}')
const GET_FORUM_COMMENTS_LIST_URL = prefixAPI('comment/list/{object_id}/{obj_content_type}/forum/{?limit,offset}')
const GET_COMMENTS_CHILDREN_URL = prefixAPI('comment/get_children/{?comment_id}')

export const createComment = createResource(COMMENT_CREATE_URL, postResource);
export const getCommentList = createResource(GET_COMMENTS_LIST_URL);
export const getForumCommentList = createResource(GET_FORUM_COMMENTS_LIST_URL);
export const getCommentChild = createResource(GET_COMMENTS_CHILDREN_URL);
export const removingComment = createResource(COMMENT_DELETE_URL, deleteResource, simpleTransformer);
