/* eslint-disable */
import Text from './Text/Text';
import textText from './Text/text';

import File from './File/File';
import textFile from './File/text';

export default {
  text: {
    text: textText,
    component: Text,
  },

  file: {
    text: textFile,
    component: File,
  },
};
