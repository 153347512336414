import { createResource, deleteResource } from '@resource/resource';

import { prefixAPI } from '@resource/api';
import {
  simpleTransformer,
} from '@resource/transformer'

const GET_NOTIFY_LIST = prefixAPI('notification/list/{?limit,offset}')
const VIEW_NOTIFY = prefixAPI('notification/view/{id}/')
const DELETE_NOTIFY = prefixAPI('notification/delete/{id}/')
const NEW_NOTIFY_COUNT = prefixAPI('notification/new/')

export const getNotifyList = createResource(GET_NOTIFY_LIST);
export const viewNotify = createResource(VIEW_NOTIFY);
export const newNotifyCount = createResource(NEW_NOTIFY_COUNT);
export const delNotify = createResource(DELETE_NOTIFY, deleteResource, simpleTransformer);
