export class FileObject {
  constructor(file) {
    this.file = file;
    this.name = this.file.name;
    this.size = this.file.size;
    this.type = this.file.type;
    this.url = null;

    this.updateUrl(this.file);
  }

  updateUrl(file = this.file) {
    const reader = new FileReader();
    // eslint-disable-next-line no-return-assign
    reader.onload = e => this.url = e.target.result;
    reader.readAsDataURL(file);
  }
}

export const getFile = async file => {
  const element = {
    file,
    name: file.name,
    size: file.size,
    type: file.type,
    url: null,
  }
  // eslint-disable-next-line no-return-assign
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = e => {
      const { result } = e.target
      element.url = result
      resolve(element)
    }
    reader.onerror = reject
  })
}
