import {
  createResource,
  postResource,
  patchResource,
  // sendFileResource,
  deleteResource,
} from '@resource/resource'

import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const MODEL = 'chat'

// SET ONLINE
const SET_ONLINE = prefixAPI('/online/set/', MODEL)

export const onlineSet = createResource(
  SET_ONLINE, postResource
)

// ROOMS
const MODEL_ROOMS = `${MODEL}/rooms`

const CREATE_ROOM = prefixAPI('/create/', MODEL_ROOMS)
const GET_ROOMS_LIST = prefixAPI('/list/{?limit,cursor,filters}', MODEL_ROOMS)
const DELETE_ROOM = prefixAPI('/{id}/destroy/', MODEL_ROOMS)

export const createRoom = createResource(
  CREATE_ROOM, postResource
)
export const getRoomsList = createResource(GET_ROOMS_LIST)

export const deleteRoom = createResource(
  DELETE_ROOM, deleteResource, simpleTransformer
)

// MESSAGES
const MODEL_MESSAGES = `${MODEL}/message`

const CREATE_MESSAGE = prefixAPI('/create/', MODEL_MESSAGES)
const READ_MESSAGE = prefixAPI('/read/', MODEL_MESSAGES)
const DELETE_MESSAGE = prefixAPI('/delete/', MODEL_MESSAGES)
const GET_UNREAD_MESSAGES_COUNT = prefixAPI('/unreaded/', MODEL_MESSAGES)
const GET_MESSAGES_LIST = prefixAPI('/{id}/messages/{?limit,cursor}', MODEL_ROOMS)
const UPDATE_MESSAGES = prefixAPI('/{id}/update/', MODEL_MESSAGES)

export const createMessage = createResource(
  CREATE_MESSAGE, postResource
)
export const readMessage = createResource(
  READ_MESSAGE, postResource
)
export const deleteMessage = createResource(
  DELETE_MESSAGE, postResource
)
export const getUnreadCount = createResource(GET_UNREAD_MESSAGES_COUNT)
export const getMessagesList = createResource(GET_MESSAGES_LIST)
export const updateMessage = createResource(
  UPDATE_MESSAGES, patchResource
)
