export const WINDOW_VISIBILITY_SIMPLE = document && 'undefined' === typeof document.hidden;

export class WindowVisibility {
  visible = false;

  constructor() {
    this.change = this.change.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  focus() {
    this.visible = true;
  }

  blur() {
    this.visible = false;
  }

  change() {
    this.visible = !document.hidden;
  }

  bind() {
    if (!WINDOW_VISIBILITY_SIMPLE) {
      this.change();
      document.addEventListener('visibilitychange', this.change);
    }

    window.addEventListener('focus', this.focus);
    window.addEventListener('blur', this.blur);

    return this;
  }

  unbind() {
    if (!WINDOW_VISIBILITY_SIMPLE) {
      document.removeEventListener('visibilitychange', this.change);
    }

    window.removeEventListener('focus', this.focus);
    window.removeEventListener('blur', this.blur);

    return this;
  }
}
