<template lang="pug">
include /mixins
+b.palyer-main-wrapper
  +b.ds-aspect-ratio.--ratio_16x9.--appearance_filled.--appearance_cuted
    vimeo-player(
      ref="player"
      class='video-player-js ds-aspect-ratio__body'
      :player-height="height"
      :player-width="width"
      :video-id="nowPlaying"
      :options='options'
      @play="isPlaying"
      @ended="end"
      @pause='pause'
      @bufferstart="start"
    )
  +b.SECTION.video__play-video.control-elements-js
    +e.BUTTON.play-button.icon-play-video(@click="play")
    +e.play-text(v-if="hasAccess !== 'True'") {{_('Смотреть 30 сек')}}
  +b.preloader(
    v-if="loading"
  )
    +e.element.icon-preloader
</template>
<script>
import {
  getVimeoId,
} from '@utils/utils'
import { modalOpener } from '@utils/helpers'
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin'

const BuySubscription = () => import('../UiElements/Modals/Interaction-modals/BuySubscription');

export default {
  name: 'VideoTemplate',
  mixins: [LoadingStateMixin],
  props: ['urlIntro', 'url', 'hasAccess', 'isAuth', 'forAuth'],
  data() {
    return {
      loading: false,
      nowPlaying: this.urlIntro,
      isAuthenticated: false,
      intro: this.urlIntro,
      videoIsPlaying: false,
      controlElements: '',
      videoPlayer: '',
      height: '100%',
      width: '100%',
      options: {
        controls: 'false',
        title: 'false',
      },
      buyModalConfig: {
        props: {
          isComponent: BuySubscription,
          title: this._('Для того, чтобы просмотреть это видео целиком, улучшите свой абонемент.'),
          variables: {
            theme: 'dark',
            isAuth: false,
          },
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: 593,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.openAuthModal,
      },
      needAuthModalConfig: {
        props: {
          title: this._('Для того, чтобы просмотреть это видео целиком, вы должны быть авторизированы.'),
          variables: {
            theme: 'dark',
            isAuth: false,
          },
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: 593,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.openAuthModal,
      },
    }
  },
  methods: {
    modalOpener,
    // eslint-disable-next-line
    isPlaying(event, player){
      this.controlElements.forEach(element => {
        element.classList.add('disabled')
      });
    },
    start() {
      this.loading = true
      this.$load(
        this.$refs.player.play()
      ).then(() => {
        this.loading = false
      })
    },
    // eslint-disable-next-line
    play(event, player) {
      this.controlElements.forEach(element => {
        element.classList.add('disabled')
      });
      this.$refs.player.play()
    },
    // eslint-disable-next-line
    pause(event, player) {
      if (this.videoIsPlaying) {
        this.controlElements.forEach(element => {
          element.classList.remove('disabled')
        });
      }
    },
    // eslint-disable-next-line
    openModal() {
      if (this.nowPlaying !== this.urlIntro) {
        if ('True' !== this.hasAccess) {
          if (this.forAuth) {
            this.modalOpener(this.needAuthModalConfig)
          } else {
            this.modalOpener(this.buyModalConfig)
          }
        }
      }
    },
    // eslint-disable-next-line
    end(event, player) {
      this.openModal()
      this.nowPlaying = getVimeoId(this.url)
      this.playerReady = true
      this.options.controls = true
      this.options.autoplay = true
      this.controlElements.forEach(element => {
        element.classList.add('position-changed')
      });
      setTimeout(() => {
        this.videoIsPlaying = true
      }, 2000)
    },
  },
  computed: {
    videoID() {
      return getVimeoId(this.urlIntro)
    },
  },
  mounted() {
    this.videoPlayer = document.querySelector('.video-player-js')
    this.nowPlaying = this.urlIntro
    this.controlElements = document.querySelectorAll('.control-elements-js')

    if ('True' === this.isAuth) {
      this.isAuthenticated = true
      this.buyModalConfig.props.variables.isAuth = true
    }
  },
}
</script>
