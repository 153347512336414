import { MessageComponentMixin } from '../../mixins';

import {
  formatBytes,
} from '../../../../utils/file_size'
/**
 * Component to display message on the 'file' type.
 *
 * **USED INTERNALLY**
 *
 * @version 0.1.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 */

export default {
  mixins: [MessageComponentMixin],

  bem: {
    block: 'wds-chat-message-file',
    modifierProps: ['alignment', 'devoted', 'type'],
  },

  methods: {
    formatBytes,
  },
}
