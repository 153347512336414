<template lang="pug">
include /mixins
+b.D-CONTROL-MULTISELECT(
      :key="key"
      label='title'
      id="order"
      name='order',
      v-model='internal',
      :value="internal"
      :options='options'
      track-by="id"
      :placeholder="_('Сортировка по дате')",
      :allow-empty="true"
      @select="update"
      :searchable="false",
      v-bem:d-control-multiselect="{'variant': 'dark' , 'width': 'lg-special'}"
     )

</template>

<script>

export default {
  props: {
    filters: {},
  },
  data() {
    return {
      name: 'ForumFilter',
      options: [{
        id: '-created_at',
        title: this._('По дате создания'),
      },
      {
        id: 'created_at',
        title: this._('По дате создания (обратная хронология)'),
      },
      {
        id: '-updated_at',
        title: this._('По дате обновления'),
      },
      {
        id: 'updated_at',
        title: this._('По дате обновления (обратная хронология)'),
      },
      ],
      internal: null,
      key: 23,
    };
  },
  mounted() {
    if (this.filters?.filters?.order) {
      console.log(this.filters?.filters?.order);
      this.internal = this.options.find(({ id }) => this.filters?.filters?.order[0] === id)
      console.log(this.internal);
    }
  },
  methods: {
    update(value) {
      this.$emit('update:filters', { ...this.filters, filters: { order: value.id } })
    },
  },
};
</script>
