<template lang="pug">
include /mixins
+b.accept-cookie(
  v-if="!isAccepted"
)
  container(
    fit="md",
  )
    //- +e.IMG.img(
    //-   src="/static/img/cookies.png"
    //- )
    row(
      appearance="spaced",
      :justify="['center', 'end-md']",
      align="center",
    )
      cell(cols="12 auto-sm")

        +b.cookie-text
          | {{ _("Мы используем cookie-файлы для наилучшего представления нашего сайта. Продолжая использовать этот сайт, вы соглашаетесь с использованием cookie-файлов.") }}
      cell(
        cols="narrow",
        v-if="cookieLink && cookieLink.length",
      )
        +b.A.btn.btn-red(
          :href="cookieLink"
          target="_blank",
        )
          ds-panel(space="3xl-lg"): ds-panel-element(offset="horizontal") {{ _("More detail") }}
      cell(cols="narrow")
        +b.BUTTON.btn.btn-red(
          type="button",
          @click="acceptCookie"
        )
          ds-panel(space="3xl-lg"): ds-panel-element(offset="horizontal") {{ _("Принять") }}

</template>

<script>

import Cookie from 'js-cookie';

export default {
  data() {
    return {
      isAcceptedCookie: false,
      accepted: false,
      cookieLink: window.CONFIG.COOKIES,
    };
  },
  computed: {
    isAccepted() {
      return this.accepted || this.isAcceptedCookie;
    },
  },
  created() {
    this.isAcceptedCookie = !!Cookie.get('bake_cookie');
  },
  methods: {
    acceptCookie() {
      Cookie.set('bake_cookie', true, { expires: 365 });
      this.accepted = true;
    },
  },
};
</script>
