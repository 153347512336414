export function getVue(Constructor) {
  let Vue = Constructor;

  while (0 !== Vue.cid) {
    Vue = Vue.super;
  }

  return Vue;
}

export function createService(service, instance) {
  return getVue(instance.constructor).extend(service);
}

export function instantiateService(Service, component) {
  return new (createService(Service, component))();
}
