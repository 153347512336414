import MenuTrigger from './MenuTrigger'
import ModalTrigger from './ModalTrigger'
import AuthTrigger from './AuthTrigger'
import LogoutTrigger from './LogoutTrigger.vue'
import ToCabinet from './ToCabinet.vue'

export default function install(Vue) {
  Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('MenuTrigger', MenuTrigger)
  Vue.component('AuthTrigger', AuthTrigger)
  Vue.component('LogoutTrigger', LogoutTrigger)
  Vue.component('ToCabinet', ToCabinet)
}
