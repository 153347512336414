<template lang="pug">
include /mixins
+b.SECTION.subscriptions
  +e.list.g-row.--justify_center.--align_stretch.--appearance_spaced-lg.--space_2xl
    template(v-for="(subscription, index) in subscriptionList" )
      +b.g-cell.g-cols.--narrow(
        :key="subscription.id"
        :class="subscription.usersubscription_set.length > 0 ? 'g-cols--spaced_lg' : 'g-cols--spaced_md' "
        )
        +b-context('subscriptions')
          +e.item-wrapper.relative
            +b-context('subscriptions')
              +e.item
                +b-context('subscriptions')
                  +e.title {{subscription.title}}
                +b-context('subscriptions')
                  +e.text.--grow_enabled {{subscription.short_text}}
                  vue-selection-controller(
                      :options="subscription.subscriptionperiodvariant_set"
                      :multiple="false"
                      :key-getter="$options.keyGetter"
                      v-model="form"
                      v-slot="controller"
                    )
                    //- :class="isFreezing ? '' : subscription.usersubscription_set.length > 0 ? 'none_events' : ''"
                    +e.price-wrapper(
                      :class="index === 0 ? 'subscriptions__price-wrapper--offset-bottom_lg' : ''"
                    )
                      //- +set period_list = subscription.subscriptionperiodvariant_set.all()
                      +e.price.--free.--first(v-if="index === 0")
                        +e.price-num {{'0$'}}
                        +e.price-descr(
                            :class="indx > 0? 'subscriptions__price-descr--secondary' : '' "
                          ) {{_('Month')}}
                      template(v-for="(option, indx, name) in controller.options")
                        +e.price-current-wrapper(
                          :class="option.value === form? 'subscriptions__price-current-wrapper--selected' : '' "
                        )
                          +e.price(
                            :key="option.key"
                          :class="subPeriodClasses[indx]"
                            @click.prevent="isFreezing? isFreezingNowModal() : !option.disabled? controller.change(option.value): null"
                          )
                            +e.price-num(
                              :class="indx > 0? 'subscriptions__price-num--secondary' : '' "
                            ) {{option.value.price + '$'}}
                            +e.price-descr(
                              :class="indx > 0? 'subscriptions__price-descr--secondary' : '' "
                            ) {{option.value.duration_text}}
                +b-context('subscriptions')
                  +e.UL.available-list
                    //- +set subscription_advantages = subscription.advantages.all()
                    //- +for('advatage in all_adventages')
                    //-   +if('advatage in subscription_advantages')
                    template(v-for="(advatage, indx) in subscription.advantages")
                      +e.LI.available-item(:key="indx")
                        +e.available-icon.icon-checked(
                          :class="advatage.enable ? 'subscriptions__available-icon--active' : ''"
                        )
                        +e.P.available-text(
                          :class="advatage.enable ? 'subscriptions__available-text--active' : ''"
                        ) {{advatage.text}}
                  template(v-if="index === 0")
                    +b.subscriptions__button.--dark.none_events {{_('По умолчанию')}}
                  template(v-else-if="subscription.usersubscription_set.length > 0 && !includesCheck(form, subscription.subscriptionperiodvariant_set)")
                    +b.subscriptions__button.--dark(
                      :class="{ 'none_events': form.id }"
                    )
                      | {{ form.id ? _('Текущий') : _('Продлить') }}
                  template(v-else)
                    purchase-item(
                      :key="form.id || subscription.id"
                      :initial="{ content_type: 'subscriptionperiodvariant', id: form.id }"
                      #default="{ purchase, loading, non_field_errors }"
                    )
                      +b.BUTTON.subscriptions__button(
                        :class="isFreezing ? 'inactive' : includesCheck(form, subscription.subscriptionperiodvariant_set) ? '' : 'inactive' "
                        type="button"
                        @click="purchase"
                        :disabled="loading"
                      ) {{subscription.usersubscription_set.length ? _('Продлить') : _('Купить')}}
              +e.terms-info
                +b.subscription-freezing(v-if="subscription.usersubscription_set.length > 0 && isFreezing")
                  +b-context('subscriptions')
                    +e.P.term.--pause {{`${subscription.usersubscription_set[0].pause.pause_type}, `}}{{_("до:")}}
                      +e.SPAN.term-date.--pause {{subscription.usersubscription_set[0].pause.pause_ended_at}}
                +b.subscription-date(
                  v-if="subscription.usersubscription_set.length > 0"
                  :class="!isFreezing? 'subscription-date--shadowed' : ''"
                  )
                  +b-context('subscriptions')
                    +e.P.term {{_("Срок действия до:")}}
                      +e.SPAN.term-date {{subscription.usersubscription_set[0].ended_at}}
            //- +if("user.is_authenticated")
              +if('period_list')
                +if('subscription.usersubscription_set.exists()')
            //- +e.BUTTON.button(disabled): +trans Текущий
            //- +else
            //- +exp purchase(subscription)
            //-     +else
            //-     +e.BUTTON.button(disabled): +trans Текущий
            //-   +else
            //-   modal-trigger(
            //-     name='login'
            //-     url='UiElements/Modals/Auth/Login'
            //-   )
            //-     template(#default='{ open }')
            //-       +b.BUTTON.subscriptions__button(
            //-         @click.prevent="open"
            //-       ): +trans Купить
            //- vue-selection-controller(
            //-   :options="sinitialData"
            //-   :multiple="false"
            //-   :key-getter="$options.keyGetter"
            //-   v-model="form"
            //-   v-slot="controller"
            //- )
            //-   +b.g-row.--justify_between.--appearance_spaced.--align_stretch.--space_xl
            //-     +b.subscriptions__price-num.subscriptions__price-num--secondary {{form}}
            //-     +b.g-cell.g-cols--12(
            //-       v-for="(option, indx, name) in controller.options" :key="option.key"
            //-       )
            //-       //- :class="form.includes(option.value) ? 'ds-panel--style_shipping-checkbox-active' : '' "
            //-       +b.ds-panel.--space_md.--style_shipping-checkbox(
            //-       @click.prevent="!option.disabled? controller.change(option.value): null"
            //-       )
            //-         +e.element(
            //-           :key="option.key"
            //-         )
            //-           +b.g-row.--justify_center.--align_center
            //-             +b.g-cell.g-cols--12.--narrow-md
            //-               +b.ds-panel.--space_none.--space_md-md
            //-                 +e.element.--offset_left
            //-                   +b.subscriptions__price-num.subscriptions__price-num--secondary {{ option.value.title }}
</template>

<script>

import { modalOpener } from '@utils/helpers'
import { includes } from 'ramda';
// const TrainingSignUp = () => import('../Interaction-modals/TrainingSignUp');
// const BuySubscription = () => import('../Interaction-modals/BuySubscription');
// const AuthModal = () => import('../Auth/Login');

export default {
  name: 'SubscriptionTemplate',
  props: ['subList', 'isFreezing'],
  keyGetter: x => x.id,
  data() {
    return {
      offerSubscriptionType: this.$route.query?.subscription || undefined,
      subPeriodClasses: [
        'subscriptions__price--first',
        'subscriptions__price--second',
        'subscriptions__price--third',
      ],
      currentValue: '',
      form: {
        id: '0',
      },
      subscriptionList: [],
    };
  },
  mounted() {
    this.subscriptionList = this.subList
    if (this.offerSubscriptionType) {
      this.form = this.subscriptionList.find(({ type = undefined }) => type === this.offerSubscriptionType)?.subscriptionperiodvariant_set[0] || { id: 0 }
    }
  },
  methods: {
    modalOpener,
    test(value) {
      this.currentValue = value
    },
    includesCheck(a, b) {
      return includes(a, b)
    },
    isFreezingNowModal() {
      this.modalOpener({
        props: {
          title: this._('Действие абонемента приоставновлено, для всенения изменений обратитесь к менеджеру'),
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      })
    },
  },
};
</script>
