<template lang="pug">
include /mixins
+b.toggle-language-wrapper(
    :class="classes? classes : ''"
  )
  +b.g-row.--align_center
    +b.g-cell.g-cols
      +b-context('toggle-language')
        +e.SPAN.text.text-ru(:class='{active: languageBin}') {{ _("RU") }}
    +b.g-cell.g-cols
      +b.toggle-language.toggle-language--en
        +e.LABEL.label
          +e.INPUT.checkbox(
            :ref="'langCheck'"
            @change="changeLang"
            type='checkbox'
            )
          +e.SPAN.slider-round(@click='languageBin = !languageBin')
    +b.g-cell.g-cols
      +b-context('toggle-language')
        +e.SPAN.text.text-en(:class='{active: !languageBin}') {{ _("EN") }}
</template>

<script>
import { prefixLanguage } from '@/js/utils/urls';

export default {
  props: ['langLink', 'currentLanguage', 'classes'],
  data() {
    return {
      languageBin: true,
      nextLang: '',
    };
  },
  mounted() {
    if ('en' === this.currentLanguage) {
      this.languageBin = false
      this.$refs.langCheck.checked = true
    } else {
      this.nextLang = 'en'
    }
  },
  methods: {
    changeLang() {
      if (this.$route.name) {
        window.location.href = prefixLanguage(this.$route.path, { language: this.nextLang })
      } else {
        window.location.href = this.langLink
      }
    },
  },
};
</script>
