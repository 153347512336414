<template lang="pug">
  include /mixins
  +b-context('comments')
    +e.wrapper(:key="comment.id")
      +e.content(
        v-if="!comment.complainted || comment.complainted && +isAuth === +comment.user.id"
        :class="comment.depth > 0? comment.depth > 3? `comments__content--layer-3` : `comments__content--layer-${comment.depth}` : ''"
      )
        +e.img-wrapper(
            :class="comment.asCoach ? 'comments__img-wrapper--is-coach' : ''"
          )
          +e.comment-img
            +b.ds-avatar.--bg_none.--appearance_rounded.--size_full(v-if="comment.user.avatar")
              +e.IMG.image(:src="comment.user.avatar")
            +b.ds-avatar.--appearance_rounded.--size_full(v-else)
              +e.label.--size_lg {{comment.user ? comment.user.username[0] : ""}}

        +e.content-wrapper(v-if="comment.complainted && +isAuth === +comment.user.id")
          +e.name {{comment.user.username}}
            +e.icon
              +e.A.img.icon-comment-multiple-outline(href="#")
          +e.P.text(
            :class="comment.complainted? 'comments__text--complainted' : ''"
          ) {{_('Комментарий был удален по причине некорректности')}}
        +e.content-wrapper(v-else-if="!comment.complainted")
          +e.name {{comment.user.username}}
            +e.icon(
              v-if="isAuth && isAuth != comment.user.id"
            )
              start-conversation(
                :interlocutor="comment.user.id"
                :username="comment.user.username"
                v-slot="{ open }"
              )
                +e.A.img.icon-comment-multiple-outline(
                  href="#"
                  @click.prevent="open"
                )
          +e.date {{comment.createdAt}}
          +e.P.text {{comment.text}}
          +e.buttons()
            +e.BUTTON.answer(
              @click.prevent="emitEvent('send-answer', comment.id)"
              ) {{_('Ответить')}}
            +e.SET-LIKES.like-button(
                :id="comment.id"
                :type="comment.contentType"
                :is-liked="comment.isLiked"
                :like-count="comment.likeCount"
                :key="`set-like_${comment.id}`"
              )
            +e.complain.icon-alert-outline(
                v-if="!comment.asCoach"
                @click.prevent="emitEvent('send-complain', comment.id)"
                :key="`set-complain_${comment.id}`"
              )
            template(v-if="parseInt(isAuth) === comment.user.id")
              +e.delete-btn.--comment.icon-close(
                @click.prevent="emitEvent('remove-comment', comment.id)"
              )
      template(v-if="comment.haveChildren")
        template(v-if="!comment.complainted")
          comment-detail(
            v-for='child in comment.replies'
            v-bind="$attrs"
            :hasAccess="hasAccess"
            :key='child.id'
            :comment='child'
            v-on="$listeners"
          )
</template>
<script>

export default {
  name: 'CommentDetail',
  props: {
    comment: Object,
    hasAccess: Boolean,
  },
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
    };
  },
  methods: {
    emitEvent(event, data) {
      if (!this.isAuth || this.hasAccess) {
        this.$emit(event, data)
      } else {
        this.$emit('improve-subscription')
      }
    },
  },
}
</script>
