import './public-path';

import Vue from 'vue';
import Router from 'vue-router'
import I18n from 'vue-i18n';

import { extend } from 'vee-validate';
import '@/js/validation';
import { install } from '@/install';
import { createApp } from '@/app';

import '@utils';

import '@webcase/vue-chat/src/styles.sass';
import './styles/index.sass';
// import '@webcase/vue-chat/src/styles/avatar.sass';

import {
  required, confirmed, email, min, min_value as minValue, max, max_value as maxValue, ext, size,
} from 'vee-validate/dist/rules';

Vue.config.productionTip = false;

Vue.use(install);

const { app, i18n, router } = createApp({
  Vue,
  Router,
  I18n,
});

export { router };

function _(...arg) {
  return i18n.t(...arg);
}

app.$mount('#app');

extend('numeric', { ...required, message: _('Поле должно быть числом.') });
extend('required', { ...required, message: _('Поле не должно быть пустым.') });
extend('confirmed', { ...confirmed, message: _('Пароли не совпадают.') });
extend('min', {
  ...min,
  message: (e, a) => _('Количество символов должно быть не меньше ') + a.length,
});

extend('min_value', { ...minValue, message: _('Minimum value is {min}.') });
extend('max', {
  ...max,
  message: (e, a) => _('Количество символов не должно превышать ') + a.length,
});
extend('max_value', { ...maxValue, message: _('Maximum value is {max}.') });
extend('ext', { ...ext, message: _('Invalid file format') });
extend('email', { ...email, message: _('Email is invalid.') });
extend('size', {
  ...size,
  message: (e, a) => _('The file size must be less than ') + a.size / 1024 + _('Mb'),
});

const MOBILEREG = /^[+() -\d]+$/;

extend('phone', {
  validate(value) {
    // Regex for a international phone number
    return MOBILEREG.test(value);
  },
  message: _('Неверный формат телефонного номера.'),
});

extend('emailMethod', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ\+0-9_.-]{2,64}@[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ0-9_.-]+[.a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{0,6}$/.test(value);
  },
  message() {
    return _('Неверный формат электронного адреса.');
  },
});
