/* eslint-disable class-methods-use-this */
import { Initable } from './Initable';

export default class Paginator extends Initable {
  init(info = {}) {
    this.info = info;
  }

  hasNext() {
    throw new Error('Implement `hasNext` method in a subclass.');
  }

  hasPrevious() {
    throw new Error('Implement `hasPrevious` method in a subclass.');
  }

  next() {
    throw new Error('Implement `next` method in a subclass.');
  }

  previous() {
    throw new Error('Implement `previous` method in a subclass.');
  }

  getPage() {
    throw new Error('Implement `getPage` method in a subclass.');
  }
}
