import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import VueBem from '@aspectus/vue-bem'
import VueDropzone from '@aspectus/vue-dropzone';
import VModal from 'vue-js-modal'
import Tag from '@aspectus/vue-tag'
import VueSelectionController from '@aspectus/vue-selection-controller';
import VueMultiselect from 'vue-multiselect'
import VueDatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import VueGrid from '@aspectus/vue-grid'
import Slick from 'vue-slick'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueSocialSharing from 'vue-social-sharing'
import Toasted from '@gitlab/vue-toasted';
import VTooltip from 'v-tooltip';
// chat components
import VueDermis from '@aspectus/vue-dermis';
import StartConversation from '@app/Chat/StartConversation.vue'
import ChatProvider from '@app/Chat/ChatProvider';
import ChatComponents from '@webcase/vue-chat/src';
import VueObserveVisibility from 'vue-observe-visibility';

import { mask } from 'vue-the-mask'
import Components from '@app'
import Resources from './js/resource';

export function install(Vue) {
  Vue.use(VueI18n);
  Vue.use(Tag);
  Vue.use(VueSelectionController);
  Vue.use(VueGrid);
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(VueDropzone, {
    name: 'vue-dropzone',
  });
  Vue.use(Components)
  Vue.component('vue-multiselect', VueMultiselect)
  Vue.component('vue-date-picker', VueDatePicker);
  Vue.component('slick', Slick)
  // chat components
  Vue.use(ChatComponents);
  Vue.component('chat-provider', ChatProvider);
  Vue.use(VueDermis);
  Vue.component(StartConversation.name, StartConversation)

  Vue.use(VModal, { dynamic: true })
  Vue.use(VueRouter)
  Vue.use(vueVimeoPlayer)
  Vue.use(VueSocialSharing)
  Vue.use(Toasted)
  Vue.use(VTooltip);
  Vue.use(Resources);
  Vue.use(VueObserveVisibility)
  Vue.directive('mask', mask)
}

export default { install };
