<template lang="pug">
include /mixins

+b.g-row.--space_none
  +b.progress-bar
    +e.element
      +b.progress-ring2.SVG(ref="progressRing2" width="120" height="120")
        +b.progress-ring__circle.--under.CIRCLE(ref="progressRingCircle2" cx="60" cy="60" r="40")
    +b.progress-ring.SVG(ref="progressRing" width="120" height="120")
      +b.progress-ring__circle.CIRCLE(ref="progressRingCircle" cx="60" cy="60" r="40")
    +e.text-field
      +b.ds-caption.progress-num.P
        | {{percent + '%'}}
</template>
<script>
export default {
  name: 'ProgressBar',
  props: ['percent'],
  data() {
    return {
    };
  },
  watch: {
    percent: function barPercentSet() {
      this.progressSettings()
    },
  },
  methods: {
    progressSettings() {
      const ringsData = this.$refs.progressRing;
      const circleData = this.$refs.progressRingCircle;

      // const ringsElement = ringsData;
      const radius = circleData.r.baseVal.value;
      const circumference = 2 * Math.PI * radius;
      const offset = circumference - (this.percent / 100) * circumference;

      ringsData.style.strokeDasharray = `${circumference} ${circumference}`;
      ringsData.style.strokeDashoffset = circumference;
      ringsData.style.strokeDashoffset = offset;
    },
  },
  mounted() {
    this.progressSettings();
  },
};
</script>
