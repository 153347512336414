import { createResource } from '@resource/resource';

import { prefixAPI } from '@resource/api';

const USER_DATA_SIDEBAR_URL = prefixAPI('cabinet/user/');
// тренировки
const USER_TRAININGS_LIST_URL = prefixAPI('cabinet/trainings/{?limit,offset}')
// подписки
const USER_SUBSCRIPTIONS_URL = prefixAPI('cabinet/subscription/')
// курсы
const USER_COURSES_URL = prefixAPI('cabinet/courses/{?limit,offset}')

const USER_COURSE_URL = prefixAPI('cabinet/courses/{id}/')
// покупки
const USER_PURCHASE_URL = prefixAPI('cabinet/purchase/')
// подписки
const USER_FAVORITES_URL = prefixAPI('cabinet/favorites/{?limit,offset}')
// темы
const USER_TOPICS_URL = prefixAPI('cabinet/topics/{?limit,offset}')
// библиотека
const USER_ARTICLES_URL = prefixAPI('cabinet/articles/{?limit,offset}')
const USER_PDF_ARTICLES_URL = prefixAPI('cabinet/pdf-articles/{?limit,offset}')
const USER_SIMULATIONS_URL = prefixAPI('cabinet/simulations/{?limit,offset}')
const USER_VIDEOS_URL = prefixAPI('cabinet/video/{?search,game_type,coach,video_type,publication_date,table_type,subscription_type,limit,offset}')
const VIDEO_FILTER_ATTRS = prefixAPI('cabinet/video/attributes/')
// транзакции
const USER_TRANSACTIONS_URL = prefixAPI('cabinet/transactions/{?limit,offset}')

export const usedDataSidebar = createResource(USER_DATA_SIDEBAR_URL);
export const getUserTrainings = createResource(USER_TRAININGS_LIST_URL);
export const getUserPurchase = createResource(USER_PURCHASE_URL)
export const getUserSubscriptions = createResource(USER_SUBSCRIPTIONS_URL);
export const getUserCourses = createResource(USER_COURSES_URL);
export const getUserCourse = createResource(USER_COURSE_URL);
export const getUserFavorites = createResource(USER_FAVORITES_URL);
export const getUserArticles = createResource(USER_ARTICLES_URL);
export const getUserPdfArticles = createResource(USER_PDF_ARTICLES_URL);
export const getUserSimulations = createResource(USER_SIMULATIONS_URL);
export const getUserVideos = createResource(USER_VIDEOS_URL);
export const getUserTopics = createResource(USER_TOPICS_URL);
export const getVideosFilters = createResource(VIDEO_FILTER_ATTRS);
export const getUserTransactions = createResource(USER_TRANSACTIONS_URL);
