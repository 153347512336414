<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="auth-form--label"
      )
        +b.H2.auth-form__title {{_('Изменить пароль')}}
        row(
          appearance='spaced'
        )
          +b.g-cell.g-cols--12(
            v-for='field in fields'
            :key='field.name'
          )
            +b.ds-panel.--space_xl
              +e.element.--offset_vertical
                +b.TAG(
                  :tag='field.tag || "d-control-input"'
                  :input-label='field.label'
                  :name='field.name'
                  :placeholder='field.placeholder'
                  :rules='field.rules'
                  :ref='field.name'
                  :vid="field.vid || ''"
                  :type='field.type || "text"'
                  :class='{"is-textarea": field.tag }'
                  :prepend-icon='field.icon',
                  v-model='form[field.name]'
                  v-bind='[field.props]'
                  v-bem:d-control-input="{'variant': 'light'}"
                )
          +b.g-cell.g-cols--12
            +b.BUTTON.auth-form__submit.btn--full_width.btn-red(type='submit' @click.prevent="prepareData(valid)") {{_('Сохранить')}}
          +b.vm--modal-close-button.--change-pass(
              type="button",
              @click="$emit('close')",
            ): span(aria-hidden="true") &times;
</template>

<script>
import {
  userPassChange,
} from '@api/account.service.js';

import { modalOpener } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  props: {
  },
  data() {
    return {
      passChangeAcceptedModal: {
        props: {
          title: this._('Новый пароль установлен.'),
          userType: '',
          id: '',
        },
        config: {
          width: '430',
          height: '90',
          adaptive: true,
          scrollable: true,
        },
      },
      form: {
      },
      nonFieldErrors: '',
      name: 'Login',
      fields: [
        {
          name: 'oldPassword',
          rules: 'required',
          type: 'password',
          tag: 'd-control-input',
          placeholder: this._('Текущий пароль.'),
          label: this._('Введите старый пароль'),
          cols: '12',
        },
        {
          name: 'password',
          rules: 'required',
          vid: 'confirmation',
          type: 'password',
          tag: 'd-control-input',
          placeholder: this._('Новый пароль'),
          label: this._('Введите новый пароль.'),
          cols: '12',
        },
        {
          name: 'passwordConfirm',
          rules: 'required|confirmed:confirmation',
          type: 'password',
          tag: 'd-control-input',
          placeholder: this._('•••••••••••'),
          label: this._('Введите новый пароль еще раз'),
          cols: '12',
        },
      ],
    };
  },
  methods: {
    modalOpener,
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send(data) {
      return userPassChange.execute({}, data).then(res => {
        if (202 === res.code) {
          this.modalOpener(this.passChangeAcceptedModal)
          this.$emit('close')
        }
      });
    },
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
  mounted() {
  },
};
</script>
