<script>

import MessagingService from '../../services/Messaging';
import { instantiateService } from '../../utils/services';
import DEFAULT_MESSAGE_TYPE_HANDLERS from '../Message/types';

/**
 * Component that provides chat service, based on provided feed instance.
 *
 * It's serve to provide storage for chat and author information
 * throughout all the components.
 *
 * It's required to pass only one element to a default slot, because of
 * vuejs limitations.
 *
 * Provides:
 *
 * - `chatMessagingFeed` ::
 *  Feed object, that was passed to a provider `feed` prop.
 * - `chatMessagingService` ::
 *  Service, that is binded to a passed feed.
 * - `chatMessagingMessageTypeHandlers` ::
 *  Message type handlers object,
 *  where keys is a message type name and body is a object with defined
 *  handlers for a different messate type display methods. Provided value
 *  is a merged object from passed to a `messageTypeHandlers` prop, and
 *  default message type handlers.
 *
 * @version 0.1.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsChatProvider',

  DEFAULT_MESSAGE_TYPE_HANDLERS,

  props: {
    feed: {
      type: Object,
      default: null,
    },

    messageTypeHandlers: Object,
  },

  provide() {
    return {
      chatMessagingFeed: this.feed,
      chatMessagingService: this.service,
      chatMessagingMessageTypeHandlers: {
        ...this.$options.DEFAULT_MESSAGE_TYPE_HANDLERS, ...this.messageTypeHandlers,
      },
    };
  },

  data() {
    return {
      service: this.createService(),
    };
  },

  render() {
    if (this.$slots.default) {
      return this.$slots.default[0] || null;
    }

    return this.$scopedSlots.default ? this.$scopedSlots.default({
      feed: this.feed, service: this.service,
    }) : null;
  },

  watch: {
    feed: {
      immediate: true,
      handler(value, old) {
        if (value !== old) {
          this.service.bind(value);
        }
      },
    },
  },

  activated() {
    this.service.bind(this.feed);
  },

  deactivated() {
    this.service.unbind(this.feed);
  },

  beforeDestroy() {
    this.service.unbind(this.feed);
  },

  methods: {
    createService() {
      return instantiateService(MessagingService, this);
    },
  },
};

</script>

<docs>
</docs>
