const CHAT_PREFIX = 'chat:';

const chatPrefix = event => CHAT_PREFIX + event;

export const MESSAGE_NEW = chatPrefix('message:new');
export const MESSAGE_RECEIVED = chatPrefix('message:received');
export const MESSAGE_UPDATED = chatPrefix('message:updated');
export const TYPING_STARTED = chatPrefix('typing:started');
export const TYPING_STOPPED = chatPrefix('typing:stopped');
export const ROOM_NEW = chatPrefix('room:new');
export const ROOM_UPDATED = chatPrefix('room:updated');
export const ROOM_REMOVED = chatPrefix('room:removed');
export const AUTHOR_UPDATED = chatPrefix('author:updated');
export const GLOBAL_UNREAD = chatPrefix('global:unread');
