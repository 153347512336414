import Paginator from './Base.js';

const LIMIT_DEFAULT = 10;

export default class LimitPaginator extends Paginator {
  limitMax = Infinity;

  init(info, limit) {
    super.init(info);

    this.limit = limit || info.limit || LIMIT_DEFAULT;

    if (this.limit > this.limitMax) {
      throw new Error('Limit amount is exceeds limit maximum.');
    }
    if (1 > this.limit) {
      throw new Error('Limit amount cant\'t be less that 1.');
    }
  }
}
