<script>
import LoadingStateMixins from '@aspectus/vue-loading-state-mixin';
import { renderSlim } from '@aspectus/vue-utils';
import { modalOpener } from '@utils/helpers.js';

const ConversationForm = () => import('./ConversationForm.vue');

export default {
  name: 'start-conversation',
  mixins: [LoadingStateMixins],
  props: {
    interlocutor: {
      type: [String, Number],
      required: false,
    },
    meta: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    username: {
      type: String,
    },
  },

  data() {
    return {
      roomId: null,
    };
  },

  computed: {
    userId() {
      return window.IS_AUTHENTICATED
    },
    ConversationModalConfig() {
      return {
        props: {
          isComponent: ConversationForm,
          title: `${this._('Написать сообщение ') } ${ this.username || ''}`,
          username: this.username,
          initial: {
            users: this.interlocutor ? [this.interlocutor, this.userId] : null,
            title: '',
            meta: this.meta,
            message: {
              type: 'text',
              body: { content: '' },
              meta: {},
            },
          },
        },
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      };
    },
  },

  mounted() {
  },
  methods: {
    modalOpener,
    openConversationModal() {
      return this.modalOpener(this.ConversationModalConfig);
    },
  },
  render(h) {
    return renderSlim(this.$scopedSlots.default({
      open: this.openConversationModal,
    }), h, 'tag');
  },

};
</script>
