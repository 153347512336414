<template lang="pug">
include /mixins
+b.all-videos-wrapper
  +b.g-container--variant_default.relative
    +b.SECTION.all-videos
      +b.g-row.--justify_center.--justify_between-md.--align_start
        +b.g-cell.g-cols--12.--6-md
          +b.H2.all-videos__title.section-title {{_('Все видео')}}
        +b.g-cell.g-cols--narrow
          +b.ds-panel.--space_2xl
            +e.element.--offset_bottom
              +b.videos-search(
              )
                +e.INPUT.input(
                  :placeholder='_("Введите название видео")'
                  @input="() => debounceAction()"
                  :key="'search'"
                  :vid="'search'",
                  v-model='form.search',
                  )
                +e.BUTTON.button
      +e.filters-wrapper
        +b.g-row.--appearance_spaced.--space_xl.--justify_center.--justify_between-lg
          template(v-for="filter in filterAttributes" )
            +b.g-cell.g-cols
              +b.D-CONTROL-MULTISELECT(
                  @input="getList()"
                  :key="filter.name"
                  :label="filter.name === 'coach'? 'slug' : 'title' "
                  track-by='title',
                  type="select"
                  :searchable="false"
                  :name='filter.name',
                  :value='filter.title'
                  v-model='form[filter.name]',
                  :options='filter.items'
                  :placeholder='filter.placeholder',
                  v-bem:d-control-multiselect="{'variant': 'dark', 'width': 'md'}"

                )
      +e.list.g-row.--justify_between.--space_md
        //- +b.g-cell.g-cols--narrow(v-if="!loading")
        //-   +b.preloader.relative
        //-     +b.g-row.--space_none
        //-       +b.g-cell.g-cols
        //-         +b.header__item
        //-           +e.IMG.img(src='/uploads/siteimages/logos/logo.png')
        //-       +b.g-cell.g-cols
        //-         +b.dots-5
        +b.g-cell.g-cols--narrow
          +b.H2.all-videos__title.section-title(
              v-if="videosList.items? 0 === videosList.items.length : false"
            ) {{_('Видео не найдены')}}
        template(v-for="(video, index) in videosList.items" )
          +b.g-cell.g-cols--12(:key="video.id")
            +b.all-videos__item(:key="video.id")
              +b.g-row.--justify_center.--justify_between-md.--space_2xl.--appearance_spaced.--full-width.--align_center
                +b.g-cell.g-cols--6.--12-till-md
                  +b-context('all-videos')
                    +e.item-left.g-row.--justify_between.--space_sm.--appearance_spaced.--align_center
                      +b.g-cell.g-cols--12-till-md
                        +b-context('all-videos')
                          +e.date
                            +e.calendar.icon-calendar
                            +e.date-num {{video.publicationDate}}
                      +b.g-cell.g-cols--12-till-xl
                        +b-context('all-videos')
                          +e.A.item-title(:href='video.url') {{video.title}}
                      +b.g-cell.g-cols
                        +b-context('all-videos')
                          +e.time
                            +e.time-icon.icon-time
                            +e.SPAN.time-count {{ '(' + video.duration + ' ' +_('мин') + ')' }}
                +b.g-cell.g-cols--6.--12-till-md
                  +b-context('all-videos')
                    +e.item-right.g-row.--justify_between.--space_sm.--appearance_spaced.--align_center
                      +b.g-cell.g-cols
                        +b-context('all-videos')
                          +e.hero
                            +e.hero-img
                              +b.ds-avatar--bg_none.--appearance_rounded.--size_full
                                +e.image
                                  +b.ds-avatar--bg_none.--appearance_rounded.--size_sm
                                    +e.IMG.image(:src='video.coach.avatar')
                            +e.hero-name {{_('тренер')}} {{video.coach.username}}
                          //- How to add like?
                      +b.g-cell.g-cols
                        +b-context('all-videos')
                          +e.like-button.icon-like-img(
                            :class="isAuth ? video.isLiked? 'all-videos__like-button--is-active' : '' : 'not-active'"
                            @click="setLike(video.id, video.contentType, index)"
                          )
                      +b.g-cell.g-cols
                        +b-context('all-videos')
                          +e.categories-list.g-row.--appearance_spaced.--space_md
                            +b.g-cell.g-cols
                              +b-context('all-videos')
                                +e.categories-item {{video.gameType.title}}
                            +b.g-cell.g-cols
                              +b-context('all-videos')
                                +e.categories-item {{video.videoType.title}}
                            +b.g-cell.g-cols
                              +b-context('all-videos')
                                +e.categories-item {{video.subscriptionType.title}}
      +b.g-row.--justify_start.--align_center
        +b.g-cell.g-cols--12.UI-LIMIT-PAGINATION(
          :pagination='pagination'
          @input='handlePaginate'
        )
</template>

<script>
import {
  getVideoList,
} from '@api/base.service.js';
import {
  toggleLikes,
} from '@api/likes.service.js';
import { debounce } from '@utils/helpers'
import { clone } from 'ramda';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin'

export default {
  name: 'VideoController',
  mixins: [LoadingStateMixin],
  props: ['filterAttributes', 'coach'],
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      pagination: {},
      form: {
        game_type: '',
        table_type: '',
        video_type: '',
        coach: '',
        publication_date: '',
        subscription_type: '',
        limit: '',
        search: '',
      },
      videosList: '',
      isLiked: false,
    };
  },
  created() {
    const delay = 1000;
    this.debounceAction = debounce(this.getList, delay);
  },
  methods: {
    setLike(ObjectId, ObjectType, index) {
      return toggleLikes.execute({}, { id: ObjectId, type: ObjectType }).then(res => {
        this.videosList.items[index].isLiked = res.isLiked
        // this.isLiked = res.isLiked
      });
    },
    filterPreset() {
      this.filterAttributes.forEach(element => {
        if ('coach' === element.name) {
          element.items.forEach(el => {
            if (el.slug === this.coach) {
              this.form.coach = el
            }
          });
        }
      });
    },
    getList(params = this.form) {
      const transformedData = clone(params)
      if (params) {
        transformedData.limit = 6
        transformedData.game_type = params?.game_type?.slug
        transformedData.table_type = params?.table_type?.slug
        transformedData.video_type = params?.video_type?.slug
        transformedData.coach = params?.coach?.slug
        transformedData.publication_date = params?.publication_date?.slug
        transformedData.subscription_type = params?.subscription_type?.slug
        transformedData.search = params?.search
      }
      this.$load(getVideoList.execute(transformedData)).then(res => {
        this.videosList = res.data;
        this.pagination = res.data.pagination
      });
    },
    handlePaginate(params) {
      this.getList({ ...this.form, ...params })
    },
  },
  mounted() {
    this.filterPreset()
    this.getList(this.form)
  },
};
</script>
