export function mergeAreas(source, required) {
  const areas = { ...source };

  Object.keys(required).forEach(key => {
    if (!areas[key]) {
      areas[key] = [];
    }

    areas[key] = areas[key].concat(
      required[key].filter(slot => !areas[key].includes(slot))
    );
  });
  return areas;
}

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

/**
 * Passed slots mixin
 * @mixin
 */
export const PassedSlots = {
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
  },
  methods: {
    namedSlots() {
      return Object.keys(this.$scopedSlots);
    },

    injectSlots(attrs) {
      return {
        ...(attrs || {}),
        // $scopedSlots: this.$scopedSlots,
      };
    },
  },
};

/**
 * Slot area mixin
 * @mixin
 */
export const SlotAreasMixin = {
  mixins: [PassedSlots],

  props: {
    slotAreas: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    hasSlot(name) {
      return this.$scopedSlots[name];
    },

    getSlotNames(areas) {
      return Array.prototype.concat.apply(
        [], areas.map(name => this.slotAreas[name]).filter(x => !!x)
      );
    },
  },
};

export const SlotAreasMergerMixin = {
  mixins: [SlotAreasMixin],

  currentSlotAreas: {},

  computed: {
    slotAreasMerged() {
      return mergeAreas(this.slotAreas, this.$options.currentSlotAreas);
    },
  },
};
