import BemComponentMixin from '../../utils/bem/BemComponentMixin';
import DEFAULT_MESSAGE_TYPE_HANDLERS from '../Message/types';

/**
 * Component to display information about some particular chat.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsChatPreview',

  mixins: [BemComponentMixin],

  inject: {
    messageTypeHandlers: {
      from: 'chatMessagingMessageTypeHandlers',
      default: DEFAULT_MESSAGE_TYPE_HANDLERS,
    },
  },

  bem: {
    block: 'wds-chat-preview',
    modifierProps: [],
  },

  props: {
    /**
     * List of authors, that are typing in the chat right now.
     */
    typing: {
      type: Array,
      default: () => [],
    },

    /**
     * Chat that will be displayed.
     */
    chat: {
      type: Object,
      required: true,
    },

    /**
     * Messages author.
     */
    userId: {
      type: [Number, String],
      required: true,
    },
  },

  computed: {
    author() {
      return this.chat.authors[this.userId];
    },

    interlocutors() {
      return Object.values(this.chat.authors).filter(x => x.id !== this.userId);
    },
  },

  methods: {
    extractor(message) {
      if (!message) return '';

      return this.messageTypeHandlers[message.type].text(message);
    },
  },
};
