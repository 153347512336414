<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
  ref="validator"
  v-slot="{ valid }",
  tag='form'
)
  +b.modal-wrapper(
    :class="`modal-wrapper--theme_${theme? theme : 'light'}`"
  )
    +b.g-row.--justify_center.--appearance_column
      +b.g-cell.g-cols--naroow
        +b.ds-panel.--space_3xl(
              v-if="title",
            )
          +e.element.--offset_top.--offset_horizontal
            +b.H2.auth-form__title.--offset_none {{title}}
      +b.g-cell.g-cols--narrow
        +b.ds-panel.--space_2xl(
              v-if="subtitle",
            )
          +e.element.--offset_bottom.--offset_horizontal
            +b.P.auth-form__descr.--offset_none {{subtitle}}

    +b.g-row.--appearance_spaced.--justify_center
      +b.g-cell.g-cols--12
        component(
          v-bind="$attrs",
          @finish="finish",
          @close="$emit('close')",
          :is="isComponent",
          :initial="$options.clone(initial)",
          :path="path",
          :variables='variables'
        )
  button.vm--modal-close-button(
    v-if="!isComponent"
    type="button",
    :aria-label="_('Close')",
    @click="$emit('close')",
  ): span(aria-hidden="true") &times;

</template>
<script>

import { clone } from 'ramda';

export default {
  clone,
  props: {
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    promise: Function,
    isComponent: {},
    path: {
      type: String,
    },
    variables: {
      type: Object,
    },
  },
  data() {
    return {
      theme: '',
    }
  },
  beforeDestroy() {
    if (!this.isComponent) {
      // eslint-disable-next-line
      this.promise && this.promise(true);
    }
  },
  methods: {
    finish(res) {
      // eslint-disable-next-line
      this.promise && this.promise(res);
      this.$emit('close');
    },
  },
  created() {
    this.theme = this.variables?.theme
  },
  mounted() {
    setTimeout(() => {
      if (this.path) {
        window.location.pathname = this.path;
      }
      // eslint-disable-next-line
      if (!this.isComponent) {
        this.finish(true)
        this.$emit('close');
      }
    }, 5000);
  },
};
</script>
