import {
  createReceiver,
} from '@resource/resource'

const FIRST_PAGE_OFFSET = 0

export const findParent = (fields, field) => {
  const parentIndex = fields.findIndex(el => el.name === field)
  const parent = fields[parentIndex]
  return parent
}
export const saveSearchInfo = (parent, event, visible) => {
  parent.props.search = event
  parent.props.loading = true
  let offset = (parent.props.pagination.offset +
    parent.props.pagination.limit) || 0
  if (!visible) offset = FIRST_PAGE_OFFSET
  return offset
}

export const saveResults = (parent, visible, data) => {
  parent.props.loading = false
  if (data.pagination) {
    parent.props.pagination = data.pagination
    if (visible) {
      parent.props.options.push(...data.items)
      return
    }
    parent.props.options = data.items
    return
  }
  parent.props.options = data
}

const makeRequest = async (resource, query, offset, payload) => {
  const {
    data,
  } = (await resource.execute({
    query,
    offset,
    limit: 50,
    ...payload,
  }))
  return data
}

export const getPaginatedProps = async (resourceUrl, event, field, visible) => {
  if (false === visible) return
  const parent = field

  const offset = saveSearchInfo(parent, event, visible)

  const resource = createReceiver(`${resourceUrl}{?offset,limit,search,id*}`)

  const data = await makeRequest(resource, parent.props?.search, offset)

  saveResults(parent, visible, data)
}

export const getPaginatedPropsForArray = async (resource, event, field, visible, fields, payload) => {
  if (false === visible) return

  const parent = findParent(fields, field)

  const offset = saveSearchInfo(parent, event, visible)

  const data = await makeRequest(resource, parent.props?.search, offset, payload)

  saveResults(parent, visible, data)
}
