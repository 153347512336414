<template lang="pug">
  wds-chat-provider(:feed='feed')
    div
      wds-chat-audio(
        url="https://back.files.design/media/1586",
        ref="messageAudio"
        :on="['chat:message:new']"
      )
      slot(:feed="feed", :chats-count="counter" :chat-id="chatId")
</template>
<script>
import {
  getUnreadCount,
} from '@api/chat.service';
import { newNotifyCount } from '@api/notifications.service.js'

import {
  MESSAGE_NEW,
  // MESSAGE_RECEIVED,
  // MESSAGE_UPDATED,
  // TYPING_STARTED,
  // TYPING_STOPPED,
  // ROOM_NEW,
  ROOM_UPDATED,
  // ROOM_REMOVED,
  // AUTHOR_UPDATED,
  GLOBAL_UNREAD,
} from './DataFeed/const.js';

import Feed from './DataFeed/DataFeed.js';

export default {
  props: {
    reversed: {
      type: Boolean,
      default: window.USER_REVERSED,
    },
  },
  provide() {
    return {
      chatsCounter: this.counter,
      setRoomFocus: this.setRoomFocus,
      notifyCounter: this.newNotifications,
    };
  },
  data() {
    return {
      newNotifications: {
        count: 0,
        getNewNotifyCount: this.getNewNotifyCount,
      },
      feed: new Feed(),
      counter: {
        count: 0,
      },
      focusedRooms: {},
      chatId: 0,
    };
  },
  computed: {
    userId() {
      return window.IS_AUTHENTICATED;
    },
  },
  watch: {
    $route() {
      this.getNewNotifyCount()
    },
  },
  created() {
    this.feed.emitter.on(MESSAGE_NEW, this.handleNewMessage);
    this.feed.emitter.on(GLOBAL_UNREAD, this.handleChatsCount);
    this.feed.emitter.on(ROOM_UPDATED, this.updateRoom);
    if (window.IS_AUTHENTICATED) {
      getUnreadCount
        .execute()
        .then(({ count }) => { this.counter.count = count; })
        .catch(() => null);
    }
  },
  mounted() {
    this.getNewNotifyCount()
  },
  methods: {
    getNewNotifyCount() {
      return newNotifyCount.execute({}).then(res => {
        if (200 === res.code) {
          this.newNotifications.count = res.data.item.newNotificationCount
        }
      });
    },
    updateRoom(event) {
      const { id = null } = event?.body;
      this.chatId = id || Date.now();
      this.$nextTick().then(() => {
        this.chatId = 0;
      });
    },
    setRoomFocus(id, value) {
      this.focusedRooms = { [id]: value };
    },
    handleNewMessage(event) {
      const {
        body: { room, authorId },
      } = event;

      if (
        !this.focusedRooms[room] &&
        authorId !== this.userId
      ) {
        this.$toasted.show(this._('New message'), {
          theme: 'outline',
          position: 'bottom-right',
          duration: 2500,
        });
        this.playSound();
        return;
      }
      this.playSound();
    },
    playSound() {
      if (this.$refs.messageAudio) {
        this.$refs.messageAudio.sound();
      }
    },
    handleChatsCount(event) {
      this.counter.count = event.body.count;
    },
  },
};

</script>
