import { pipe, partialRight } from 'ramda'

import {
  baseResource as superResource,
  receiveResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import {
  fetcher,
  defaultDataTransformer,
  transformDataFetcher,
  raiseHttpErrorTransformer,
} from '@resource/transformer'

import { HEADERS_MIDDLEWERE } from '@resource/middleware'

export const getResource = receiveResource
  .middleware(jsonRequestMiddleware)
  .middleware(HEADERS_MIDDLEWERE)

export const postResource = getResource
  .config('method', 'POST')

export const patchResource = getResource
  .config('method', 'PATCH')

export const deleteResource = getResource
  .config('method', 'DELETE')

export const optionsResource = getResource
  .config('method', 'OPTIONS')

export const baseResource = superResource
  .middleware(HEADERS_MIDDLEWERE)
  .transform(raiseHttpErrorTransformer)

export function createResource(
  template,
  resource = getResource,
  transformer = defaultDataTransformer
) {
  return resource
    .url(makeTemplateGetter(template))
    .fetcher(transformDataFetcher(fetcher,
      pipe(raiseHttpErrorTransformer, transformer)))
}
export const createBaseResource = (template, base = baseResource) => (
  base.url(makeTemplateGetter(template))
)
export const createReceiver = partialRight(createResource, [receiveResource])
export const createRemover = partialRight(createBaseResource, [deleteResource])
