/* eslint-disable */
import { thresholdCheck } from '../../utils/number';

export const typerHandlers = [
  {
    event: 'self:start',
    handler() {
      this.sendEvent('chat:typing:started', {
        chatId: this.chat.id,
        authorId: this.userId,
      });
    },
  },

  {
    event: 'self:stop',
    handler() {
      this.sendEvent('chat:typing:stopped', {
        chatId: this.chat.id,
        authorId: this.userId,
      });
    },
  },
];

export const feedHandlers = [
  {
    event: 'chat:message:new',
    handler(event) {
      const message = event.body;
      
      if (message.chatId == this.chat.id) {
        this.messages.data.push(message);
        
        if (message.authorId !== this.userId) {
          if (this.visibility.visible) {
            this.messagesStatus('read', [message.id]);
          } else {
            this.messagesStatus('received', [message.id]);
          }
        }
      }
    },
  },
  {
    event: 'chat:message:new',
    handler() {
      if (thresholdCheck(
        this.threshold,
        this.scroller.last.height - this.scroller.element.offsetHeight,
        this.scroller.last.top
      )) {
        this.$nextTick(() => this.scroller.toBottom());
      }
    },
  },
  {
    event: 'chat:message:updated',
    handler(event) {
      event.body.forEach(element => {
        if (element.room === this.chat.id) {
          const index = this.messages.data.findIndex(
            x => x.id === element.id
          );
  
          if (-1 !== index) {
            this.messages.data.splice(index, 1, element);
          }
        }
        
      });
    },
  },
];
