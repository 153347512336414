<template lang="pug">
  include /mixins
  +b.ds-panel.--relative
    +e.element
      +b.SECTION.profile
        vue-dropzone(
          ref="uploader"
          @drop="(e) => fileDrop(e)"
          @onLoad
        )
          template(
            #default="uploader"
          )
            +b.g-row.--justify_center
              +b.g-cell.g-cols--12
                +b-context('profile')
                  +e.BUTTON.hero-file.btn.btn-dark-trans(
                    @click.prevent="open"
                    :disabled="isDisabled"
                    :class="isDisabled? 'button--is-disabled' : '' "
                  ) {{ title? title : _('Add photo') }}
              +b.g-cell.g-cols--12
                +b.image-file-errors.LABEL(v-if='error') {{error}}
</template>
<script>
import {
  getFile,
} from '@utils/file'

export default {
  name: 'FileUploader',
  props: {
    isDisabled: {
      default: false,
    },
    title: {
      default: false,
    },
  },
  data() {
    return {
      error: '',
      preLoadedFile: [],
      maxSizeUpload: 5 * 1024,
      value: [],
      maxLengthName: 44,
    }
  },
  computed: {
    config() {
      return { acceptor: this.acceptor };
    },
    // hasRejected() {
    //   return this.$refs.uploader && this.$refs.uploader.rejected && this.$refs.uploader.rejected.length;
    // },
    // hasAccepted() {
    //   return this.$refs.uploader && this.$refs.uploader.accepted && this.$refs.uploader.accepted.length;
    // },
    // hasUnloaded() {
    //   return (!!this.hasAccepted || !!this.hasRejected);
    // },
  },
  methods: {
    // filePreLoad(event) {
    //   const file = event.accepted;
    //   const x = /^[*/]+.jpg|doc|xls|pdf$/;
    //   if (!x.test(file[0].type)) {
    //     this.error = 'Inappropriate file type!'
    //   } else if (file[0].size > (3 * 1024) * 1024) {
    //     this.error = 'The file size is too large'
    //   } else {
    //     this.preLoadFilesHandler(file[0])
    //   }
    //   setTimeout(() => {
    //     this.error = ''
    //   }, 6000);
    // },
    // async fileDrop(e) {
    //   e.accepted.forEach(async el => {
    //     const file = {
    //       preview: window.URL.createObjectURL(el),
    //       file: new FileObject(el),
    //     }
    //     this.preLoadFilesHandler(file.file)
    //   })
    //   // this.$emit('updateImage', {
    //   //   key,
    //   //   value: images,
    //   //   i: this.index,
    //   // })
    // },
    async fileDrop(e) {
      e.accepted.forEach(async el => {
        const fileElement = await getFile(el)
        const file = {
          preview: window.URL.createObjectURL(el),
          file: fileElement,
        }
        // this.list - твой массив с картинками
        // this.list.push(file)
        const x = /^[*/]+.JPEG|jpeg|PNG|png$/;
        if (!x.test(file.file.type)) {
          this.error = this._('Не подходящий тип файла!')
        } else if (file.file.size > (3 * 1024) * 1024) {
          this.error = this._(' Файл слишком большой!')
        } else {
          this.$emit('preLoadFilesHandler', {
            value: file.file,
          })
        }
        setTimeout(() => {
          this.error = ''
        }, 6000);
      })
    },
    // log(data) {
    // },
    uploadHandler({ result, descriptor }) {
      this.$refs.uploader.remove(descriptor);
      this.input(this.value.concat([result]));
    },
    input(data) {
      this.value = data;
    },
    open() {
      this.$refs.uploader.open();
    },
    remove(file) {
      const { id } = file;

      const index = this.value.findIndex(x => x.id === id);
      if (-1 !== index) {
        this.input(this.value.slice(0, index).concat(this.value.slice(index + 1)));
      }
    },
    // acceptor(file) {
    //   this.preLoadedFile = file.name
    //   const x = /^[image/]+.png|JPEG|JPG|jpg|GIF|gif|PNG|png$/;
    //   if (x.test(file.type)) {
    //     console.log('Good!')
    //   } else {
    //     this.error = 'WTF?'
    //     setTimeout(function errorReset() { this.error = '' }, 3000);
    //   }
    //   // return this.accept ? accepts(file, this.accept) : true;
    // },
  },
}
</script>
<style lang="sass">
  .vue-dropzone__input
    display: none
</style>
