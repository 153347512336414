<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
    ref="validator"
    v-slot="{ valid }",
    tag='form'
    class="profile-form"
  )
    +b.g-row.--appearance_spaced.--appearance_column
      +b.g-cell.g-cols--12(
        v-for='field in fields'
        :key='field.name'
        )
        +b.ds-panel.--space_lg
          +e.element.--offset_vertical
            +b.mail-description(v-if="field.name == 'email'")
              +e.P.icon.icon-info
                +e.SPAN.text {{_('Используется как логин при авторизации')}}
            +b.TAG(
              :tag='field.tag || "d-control-input"'
              :input-label='field.label'
              :name='field.name'
              :placeholder='field.placeholder'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :class='{"is-textarea": field.tag }'
              :prepend-icon='field.icon',
              v-model='form[field.name]'
              v-bind='[field.props]'
              v-bem:d-control-input="{'variant': 'light', 'width': 'main'}"
              :readonly="field.name === 'password' || field.name === 'email'? true : isEditind"
            )
            +b.ds-panel.--space_2xl.--full_width(
              v-if="field.name === 'email' && !isEditind"
            )
              +e.element.--offset_top
                +b.BUTTON.profile__submit.btn.btn-red(
                  type='submit'
                  @click.prevent="modalOpener(emailChangeModal)"
                  ) {{_('Изменить E-mail')}}
      +b.g-cell.g-cols--12
        +b.BUTTON.profile__submit.btn.btn-red(
          type='submit'
          v-if="!isEditind"
          @click.prevent="modalOpener(passChangeModal)"
          ) {{_('Изменить пароль')}}
      +b.g-cell.g-cols--12
        template(v-for="select in selects")
          template(v-if="select.name === 'birthday'")
            +b.ds-panel.--space_2xl.--full_width
              +e.element.--offset_top
                +b.time-label {{select.label}}
                +b.TAG(
                    :key="select.name"
                    :tag="select.tag"
                    :name='name'
                    v-model='form[select.name]'
                    v-bind="select.props"
                    :placeholder='select.placeholder'
                    :class="!isEditind? '' : 'inactive--light'"
                    v-bem:d-control-input="{'width': 'main'}"
                    :lang="datepickerLeng"
                    :default-value="`${new Date(form[select.name])}`"
                    v-mask="'##-##-####'"
                  )
          template(v-else)
            +b.ds-panel.--space_2xl.--full_width(
              v-if='select.condition()'
            )
              +e.element.--offset_top
                +b.profile-time-wrapper
                  +b.time-label {{ select.label }}
                  +b.TAG(
                    :key="select.name"
                    :tag="select.tag"
                    :name='name'
                    v-model='form[select.name]'
                    v-on='select.events'
                    v-bind="select.props"
                    :placeholder='select.placeholder'
                    :class="!isEditind? '' : 'inactive--light'"
                    v-bem:d-control-multiselect="{'width': 'main'}"
                    v-bem:d-control-input="{'width': 'main'}"

                  )
                    template(
                      #afterList=''
                    )
                      div
                        div(
                          v-if="hasNext(select.props)"
                          v-observe-visibility="visible => select.afterList(visible)",
                        )
      +b.g-cell.g-cols--12
        +b.ds-panel.--space_3xl
          +e.element.--offset_top
            +b.BUTTON.profile__submit.btn.btn-red(
              type='submit' @click.prevent="isEditind? isEditind = false : prepareData(valid)"
              )
              | {{isEditind? _('Редактировать') : _('Сохранить') }}
</template>

<script>
import {
  updateProfileData,
} from '@api/account.service.js';

import {
  getCountriesList,
  getWindowsZones,
} from '@api/common.service.js';

import {
  getPaginatedPropsForArray,
} from '@utils/valuesGetter'

import { clone } from 'ramda';
import { modalOpener } from '@utils/helpers';
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

// import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const ChangePassword = () => import('../Modals/Auth/ChangePassword');
const ChangeEmail = () => import('../Modals/Auth/ChangeEmail');

export default {
  mixins: [FormMixin],
  data() {
    return {
      isEditind: true,
      form: {
      },
      updateForm: {
        firstName: '1',
        lastName: '2',
        username: '3',
        email: '4',
        timezone: '5',
        birthday: '6',
      },
      nonFieldErrors: '',
      name: 'Profile',
      passChangeModal: {
        props: {
          isComponent: ChangePassword,
          userType: '',
          id: '',
        },
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.getpaymentModalData,
      },
      emailChangeModal: {
        props: {
          isComponent: ChangeEmail,
          email: this.form?.email,
        },
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.getNewEmail,
      },
      selects: [
        {
          condition: () => true,
          label: this._('Укажите страну*'),
          placeholder: this._('Выберите страну'),
          rules: 'required',
          tag: 'd-control-multiselect',
          type: 'select',
          name: 'country',
          class: '',
          events: {
            input: () => this.clearTimezone(),
            open: $event => getPaginatedPropsForArray(getCountriesList, $event, 'country', null, this.selects),
            'search-change': $event => getPaginatedPropsForArray(getCountriesList, $event, 'country', null, this.selects),
          },
          afterList: visible => getPaginatedPropsForArray(getCountriesList, '', 'country', visible, this.selects),
          props: {
            options: [],
            label: 'title',
            trackBy: 'id',
            search: '',
            pagination: {},
            searchable: true,
            internalSearch: false,
          },
        },
        {
          condition: () => this.form.country,
          label: this._('Укажите часовой пояс*'),
          placeholder: this._('Выберите часовой пояс'),
          rules: 'required',
          tag: 'd-control-multiselect',
          type: 'select',
          class: '',
          name: 'countrywindowszone',
          events: {
            open: $event => getPaginatedPropsForArray(getWindowsZones, $event, 'countrywindowszone', null, this.selects, { country_slug: this.form.country.label }),
            'search-change': $event => getPaginatedPropsForArray(getWindowsZones, $event, 'countrywindowszone', null, this.selects, { country_slug: this.form.country.label }),
          },
          afterList: visible => getPaginatedPropsForArray(getWindowsZones, '', 'countrywindowszone', visible, this.selects, { country_slug: this.form.country.label }),
          props: {
            label: 'name',
            trackBy: 'id',
            customLabel: obj => `${obj.windowszone.name} ${obj.windowszone.offset} ${obj.windowszone.viewId}`,
            search: '',
            searchable: true,
            internalSearch: false,
            pagination: {},
            options: [],
          },
        },
        {
          props: {
            valueType: 'DD-MM-YYYY',
            format: 'DD-MM-YYYY',
          },
          name: 'birthday',
          label: this._('Дата рождения*'),
          placeholder: this._('DD-MM-YYYY'),
          rules: 'required',
          tag: 'vue-date-picker',
        },
      ],
      datepickerLeng: {
        // the locale of formatting and parsing function
        formatLocale: {
          // MMMM
          months: [
            this._('Январь'),
            this._('Февраль'),
            this._('Март'),
            this._('Апрель'),
            this._('Май'),
            this._('Июнь'),
            this._('Июль'),
            this._('Август'),
            this._('Сентябрь'),
            this._('Октябрь'),
            this._('Ноябрь'),
            this._('Декабрь'),
          ],
          // MMM
          monthsShort: [
            this._('Январь'),
            this._('Февраль'),
            this._('Март'),
            this._('Апрель'),
            this._('Май'),
            this._('Июнь'),
            this._('Июль'),
            this._('Август'),
            this._('Сентябрь'),
            this._('Октябрь'),
            this._('Ноябрь'),
            this._('Декабрь'),
          ],
          weekdays: [
            this._('Воскресенье'),
            this._('Понедельник'),
            this._('Вторник'),
            this._('Среда'),
            this._('Четверг'),
            this._('Пятница'),
            this._('Суббота'),
          ],
          // ddd
          weekdaysShort: [
            this._('Вс'),
            this._('Пн'),
            this._('Вт'),
            this._('Ср'),
            this._('Чт'),
            this._('Пт'),
            this._('Сб'),
          ],
          // dd
          weekdaysMin: [
            this._('Вс'),
            this._('Пн'),
            this._('Вт'),
            this._('Ср'),
            this._('Чт'),
            this._('Пт'),
            this._('Сб'),
          ],
          // first day of week
          firstDayOfWeek: 1,
          // first week contains January 1st.
          firstWeekContainsDate: 1,
          // format 'a', 'A'
        },
      },
      fields: [
        {
          name: 'username',
          rules: 'required',
          type: 'name',
          tag: 'd-control-input',
          label: this._('Никнейм*'),
          placeholder: this._('Ваш никнейм'),
        },
        {
          name: 'firstName',
          type: 'name',
          tag: 'd-control-input',
          label: this._('Имя'),
          placeholder: this._('Имя'),
        },
        {
          name: 'lastName',
          type: 'name',
          tag: 'd-control-input',
          label: this._('Фамилия'),
          placeholder: this._('Фамилия'),
        },
        {
          name: 'email',
          rules: 'required',
          type: 'email',
          tag: 'd-control-input',
          label: this._('Е-mail*'),
          placeholder: this._('Ваш E-mail'),
        },
        {
          name: 'password',
          rules: 'required',
          type: 'password',
          tag: 'd-control-input',
          label: this._('Пароль'),
          placeholder: this._('•••••••••••'),
        },
      ],
    };
  },
  watch: {
    '$parent.userData': function test() {
      this.form = this.$parent?.userData
      this.form.birthday = this.form.birthday && this.form.birthday.split('.').join('-')
    },
  },
  methods: {
    modalOpener,
    getNewEmail(data) {
      this.form.email = data
      this.isEditind = true
    },
    hasNext(props) {
      const { limit, offset, total } = props.pagination
      const hasPage = offset + limit <= total
      return hasPage
    },
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    clearTimezone() {
      if (!this.form) return
      this.form.timezone = ''
      const timezoneFields = this.selects.find(el => 'countrywindowszone' === el.name)
      timezoneFields.props.options = []
      timezoneFields.props.pagiantion = {}
    },
    async send(data) {
      const transformedData = clone(data)
      // eslint-disable-next-line
      for (const key in data) {
        if (this.updateForm[key] !== undefined) {
          this.updateForm[key] = data[key]
        }
      }
      transformedData.birthday = transformedData.birthday && transformedData.birthday.split('-').join('.')
      transformedData.country = transformedData?.country?.id
      transformedData.countrywindowszone = transformedData?.countrywindowszone?.id
      delete transformedData.email
      return updateProfileData.execute({}, transformedData).then(() => {
        this.isEditind = true
        window.location.reload()
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
