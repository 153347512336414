<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
  ref="validator"
  v-slot="{ valid }",
  tag='form'
  class="send-form"
)
  row(
    appearance='spaced'
  )
    cell(
      v-for='field in fields'
      :cols='field.cols'
      :key='field.name'
    )
      +b.ds-panel.--space_none.--space_xl-xl
        +e.element--offset_top
          +b.TAG(
            :tag='field.tag || "d-control-input"'
            :input-label='field.label'
            :name='field.name'
            :placeholder='field.placeholder'
            :rules='field.rules'
            :type='field.type || "text"'
            :class="field.tag? 'is-textarea is-textarea--border_dark is-textarea--size_md ' : 'd-control-input'"
            v-model='form[field.name]'
            v-bind='[field.props]'
            v-bem:d-control-input="{'variant': 'dark'}"
          )
  +b.BUTTON.send-form__submit.btn.btn-black(type='submit' @click.prevent="prepareData(valid)") {{ _("Отправить") }}
</template>

<script>
import {
  getCallback,
} from '@api/callback.service.js';
import { modalOpener } from '@utils/helpers';
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  data() {
    return {
      form: {
        email: '',
        tel: '',
        title: '',
        message: '',
      },
      nonFieldErrors: '',
      name: 'Login',
      fields: [
        {
          name: 'email',
          rules: 'required|emailMethod',
          type: 'email',
          placeholder: this._('Ваш E-mail*'),
          cols: '12 6-sm',
        },
        {
          name: 'tel',
          rules: 'required|phone|min:8|max:14',
          type: 'tel',
          placeholder: this._('Ваш Телефон*'),
          cols: '12 6-sm',
        },
        {
          name: 'title',
          placeholder: this._('Тема'),
          cols: '12',
        },
        {
          name: 'message',
          rules: 'required',
          type: 'textarea',
          tag: 'd-control-textarea',
          placeholder: this._('Сообщение*'),
          cols: '12',
        },
      ],
      callbackModal: {
        props: {
          title: this._('Ваш запрос принят'),
          subtitle: this._('В ближайшее время с вами свяжется наш менеджер'),
        },
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.getpaymentModalData,
      },
    };
  },
  methods: {
    modalOpener,
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      return getCallback.execute({}, this.form).then(res => {
        if (201 === res.code) {
          this.modalOpener(this.callbackModal)
          // eslint-disable-next-line
          this.form.email = ''
          this.form.tel = ''
          this.form.title = ''
          this.form.message = ''
          this.$refs.validator.reset()
        }
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
