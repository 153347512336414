<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="complain-form"
      )
        +b.H2.complain-form__title {{ _("Подать жалобу на комментарий:") }}
        +b.complain-form__inner
          template(v-for="(reason, index) in reasons")
            +b.complain-form__checkbox
              +b.INPUT.complain-form__checkbox-input(
                :key="reason.id"
                type='checkbox'
                v-model='reasons[index].isTrue'
                )
            +b.P.complain-form__text {{reason.title}}
          +b.error-label.ds-caption.--size_xs.--light {{nonFieldErrors[0]}}
          +b.ds-panel.--space_lg
            +e.element.--offset_bottom
              +b.complain-form__inner
                +b.TAG(
                  :tag='field.tag || "d-control-input"'
                  :input-label='field.label'
                  :name='field.name'
                  :placeholder='field.placeholder'
                  :rules='field.rules'
                  :type='field.type || "text"'
                  :class='{"is-textarea": field.tag }'
                  v-model='form.text'
                  v-bem:is-textarea="{'size': 'sm'}"
                )
        +b.BUTTON.complain-form__submit.btn.btn-red(type='submit' @click.prevent="prepareData(valid)") {{ _("Отправить") }}

</template>

<script>
import {
  getComplaintReasons,
  createComplaint,
} from '@api/complaint.service.js';
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

export default {
  mixins: [FormMixin],
  name: 'CommentsComplaint',
  props: ['id'],
  data() {
    return {
      nonFieldErrors: '',
      reasons: '',
      form: {
        comment: this.id,
        text: '',
        reason: [],
      },
      field: {
        name: 'textarea',
        type: 'textarea',
        rules: 'required',
        tag: 'd-control-textarea',
        placeholder: this._('Жалоба на комментарий'),
      },
    };
  },
  mounted() {
    this.getReasons()
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      this.reasons.forEach(element => {
        if (element?.isTrue) {
          this.form.reason.push(element.id)
        }
      });
      return createComplaint.execute({}, this.form).then(res => {
        if (201 === res.code) {
          this.$emit('finish', this.form)
          this.$emit('close')
        }
      });
    },
    getReasons() {
      return getComplaintReasons.execute().then(res => {
        this.reasons = res.data.items
      });
    },
    close() {
      this.$emit('close')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
