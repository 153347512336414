<template lang="pug">
include /mixins
+b.DIV(
  @click="isAuth? setLike() : authModalOpener()"
  :class="likeCount !== undefined? 'all-likes' : hasLike?  'like-button ic icon-like-img is-liked' : 'like-button ic icon-like-img'"
)
  +e.all-likes__likes-icon(
    v-if="likeCount !== undefined"
    class="icon-like-img"
    :class="hasLike? 'is-liked' : ''"
    )
  +e.likes-num(v-if="likeCount !== undefined") {{likes? likes : 0}}
  //- +b.like-button.ic.icon-like-img(else)
  slot
</template>

<script>
import {
  toggleLikes,
} from '@api/likes.service.js';
import { modalOpener } from '@utils/helpers'

const LogIn = () => import('../Modals/Auth/Login');

export default {
  name: 'SetLikes',
  props: {
    isLiked: Boolean,
    id: [String, Number],
    type: String,
    likeCount: Number,
  },
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      likes: +this.likeCount,
      hasLike: this.isLiked,
    };
  },
  methods: {
    modalOpener,
    setLike() {
      return toggleLikes.execute({}, { id: this.id, type: this.type }).then(res => {
        this.hasLike = res.isLiked
        if (res.isLiked) {
          this.likes += 1
        } else {
          this.likes -= 1
        }
      });
    },
    authModalOpener() {
      this.modalOpener({
        props: {
          isComponent: LogIn,
          next: window.location.pathname,
        },
        // classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '345',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      })
    },
  },
};
</script>
