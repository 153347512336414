/* eslint-disable */ 
export function friendlyUrlParser() {
  let path = decodeURIComponent(new URL(window.location.href));
  const zero = 0
  const parsedUrl = {}
  if (path.indexOf('filters') > zero) {
    [path] = path.split('/page')
    const hashes = path.split('filters/')[1].split(';')
    hashes.map(hash => {
      const [key] = hash.split('=')
      let [, val] = hash.split('=')

      parsedUrl[key] = []
      if (val.indexOf(',') > zero) {
        val = val.split(',')
        val.forEach(v => {
          if (v.indexOf('/') > zero) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(v))
          }
        })
      } else if (val.indexOf('/') > zero) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
      } else {
        parsedUrl[key].push(decodeURIComponent(val))
      }
      return false
    })
  }
  return parsedUrl
}

