import { quillEditor } from 'vue-quill-editor'
import { createHOCc } from 'vue-hoc'

export const editorDefaults = createHOCc({
  props: [
    'globalOptions',
  ],
}, {
  props: {
    globalOptions() {
      return {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'link'],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: ['#0E1217', '#B01D2D', '#FFFFFF', '#777E88'] }, { background: ['#0E1217', '#B01D2D', '#FFFFFF', '#777E88'] }], // dropdown with defaults from theme
            [{ align: [] }],

            ['clean'],

            ['image'],
          ],
        },
      }
    },
  },
})

export default editorDefaults(quillEditor)
