<template lang="pug">
include /mixins
+b.DIV
  click-outside(:do='shareClose')
    +b.news__share.icon-share.relative(@click="shareHiden = !shareHiden")
      transition(
        name="slide-left-fade"
      )
        +b.g-row.--space_none.absolute.news__share-sub(v-if="!shareHiden")
          +b.g-cell.g-cols
            +b.P.news__share.fas.fa-link(
              @click.prevent="copyToClipboard()"
            )
          +b.g-cell.g-cols
            share-network(
              network="facebook"
              :url="shareContent"
              :title="title"
              :description="description"
              :quote="quote"
              :hashtags="hashtags? hashtags : ''"
            )
              +b.news__share.icon-fb
          +b.g-cell.g-cols
            share-network(
              network="twitter"
              :url="shareContent"
              :title="title"
              :description="description"
              :quote="quote"
              :hashtags="hashtags? hashtags : ''"
            )
              +b.news__share.icon-twitter
          +b.g-cell.g-cols
            share-network(
              network="vk"
              :url="shareContent"
              :title="title"
              :description="description"
              :quote="quote"
              :hashtags="hashtags? hashtags : ''"
            )
              +b.news__share.--vk.icon-vk
            //- +e.LI.item
            //-   +e.A.social-link.icon-google(
            //-     href="https://plus.google.com/share?url=//www.w3docs.com/learn-javascript/google-share.html"
            //-     onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,height=600,width=600');return false;"
            //-   )
</template>

<script>
export default {
  name: 'SetLikes',
  props: ['shareContent', 'title', 'description', 'quote', 'hashtags'],
  data() {
    return {
      likes: '',
      hasLike: false,
      shareHiden: true,
      toastText: this._('Ссылка скопирована в буфер обмена'),
    };
  },
  methods: {
    shareClose() {
      this.shareHiden = true
    },
    copyToClipboard() {
      this.$copyToClipboard(this.shareContent)
      this.$toasted.show(this.toastText, {
        theme: 'outline',
        position: 'top-left',
        duration: 2500,
      });
    },
  },
};
</script>
