export default class Message {
  constructor(message) {
    this.channelName = message.channel;
    this.site = message.site;
    this.msg = message.message || '';
    this.data = message.data || {};
    this.eventClass = message.event_class || '';
    this.bucket = message.bucket || '';
    this.date = new Date();
  }
}
