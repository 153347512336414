<template lang="pug">
include /mixins
+b.DIV(
  :class="!isSubscribe? blockClass : `${blockClass} color-main-red`"
  @click="!isSubscribe? setLike() : modalOpener(confirmModalConfig)"
) {{(isSubscribe? subscribe : notSubscribe)}}
  slot
</template>

<script>
import {
  toggleLikes,
} from '@api/likes.service.js';
import { modalOpener } from '@utils/helpers'

const confirmModal = () => import('../../UiElements/Modals/Interaction-modals/ConfirmModal');

export default {
  name: 'Subscribe',
  props: ['id', 'type', 'blockClass', 'hasSubscribe'],
  data() {
    return {
      isSubscribe: false,
      subscribe: this._('Подписаны'),
      notSubscribe: this._('Подписаться'),
      confirmModalConfig: {
        props: {
          isComponent: confirmModal,
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '430',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        actor: this.setLike,
      },
    };
  },
  mounted() {
    this.isSubscribe = this.hasSubscribe
  },
  methods: {
    modalOpener,
    setLike() {
      return toggleLikes.execute({}, { id: this.id, type: this.type }).then(res => {
        if (res.isLiked !== undefined) {
          this.isSubscribe = !this.isSubscribe
        }
      });
    },
  },
};
</script>
