import { CursorPaginator } from '@webcase/paginator'
import { now } from '../../utils/date';
import { thresholdCheck } from '../../utils/number';
import { WindowVisibility } from '../../utils/window-visibility';
import { StickScrollPosition } from '../../utils/stick-scroll';
import { TypeHandler } from '../../utils/type-handler';
import LoadableMixin from '../../utils/loadable/LoadableMixin';
import FeedEventsHandlerMixin from '../../utils/mixins/FeedEventsHandlerMixin';
import TyperEventsHandlerMixin from '../../utils/mixins/TyperEventsHandlerMixin';
import MessagingInjectorMixin from '../../utils/mixins/MessagingInjectorMixin';
import { SlotAreasMixin } from '../../utils/slot-areas';

/**
 * @mixin
 */
export const ChatMechanicsMixin = {
  mixins: [LoadableMixin, FeedEventsHandlerMixin],

  feedHandlers: [],

  props: {
    chat: {
      type: Object,
      required: true,
    },
    preloadAmount: {
      type: Number,
      default: 40,
    },
    userId: [Number, String],
  },

  data() {
    return {
      text: '',
      messages: {
        data: [],
        meta: {},
      },
      author: {},
    };
  },

  watch: {
    chat: { immediate: true, handler: 'watchChat' },
    'chat.authors': { immediate: true, handler: 'updateAuthor' },
    userId: { immediate: true, handler: 'updateAuthor' },
  },

  methods: {
    updateAuthor() {
      this.author = this.chat.authors[this.userId];
    },

    watchChat(value, old) {
      if (value) {
        if (!old || value.id !== old.id) {
          this.messages = { data: [], meta: {} };
          this.getInitialMessages();
        }
      }
    },

    getInitialMessages() {
      return this.pullMessages({
        endCursor: now().toISOString(),
        chatId: this.chat.id,
        limit: this.preloadAmount,
      });
    },

    paginateMessages(pagination) {
      this.pullMessages({ ...this.messages.meta, ...pagination, ...{ chatId: this.chat.id } });
    },

    pullMessages(parameters) {
      return this.$load(this.feed.receiveHistory(parameters)).then(({ data, meta }) => {
        this.messages.meta = meta;
        this.messages.paginator = new CursorPaginator({
          limit: meta.limit,
          hasNext: meta.hasNext,
          hasPrevious: meta.hasPrevious,
          startCursor: meta.startCursor,
          endCursor: meta.endCursor,
        });
        this.messages.data = data.reverse().concat(this.messages.data);

        return { data, meta };
      });
    },

    sendText() {
      if (!this.feed.isOnline) return;

      const content = this.text.trim();

      if (content) {
        this.sendMessage('text', { content });
        this.text = '';
      }
    },

    sendMessage(type, body) {
      const message = {
        type,
        body,
        authorId: this.userId,
        chatId: this.chat.id,
      };

      this.feed.sendMessage(message);
    },

    sendEvent(type, body) {
      this.feed.sendEvent(type, body);
    },
  },
};

/**
 * @mixin
 */
export const ChatRoomMixin = {
  mixins: [
    ChatMechanicsMixin,
    TyperEventsHandlerMixin,
    MessagingInjectorMixin,
    SlotAreasMixin,
  ],

  props: {
    typing: {
      type: Array,
      default: () => [],
    },
    threshold: {
      type: Number,
      default: 2,
    },
  },

  watch: {
    'visibility.visible': function (value, old) {
      if (old !== value && true === value) {
        this.readMessages();
      }
    },
  },

  data() {
    return {
      visibility: new WindowVisibility(),
      typer: new TypeHandler({ startTimeout: 800 }).bind(),
      scroller: new StickScrollPosition({
        direction: StickScrollPosition.CHANGED_ON_TOP,
      }),
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.scroller.bind(this.$refs['conversation-wrapper']);
      this.visibility.bind();
      this.scroller.toBottom();
    },

    messagesStatus(status, messagesIds) {
      this.sendEvent(`chat:message:${status}`, {
        messagesIds,
        chatId: this.chat.id,
      });
    },

    readMessages() {
      const unread = this.messages.data
        .filter(x => x.authorId !== this.userId)
        .filter(x => x.statuses.some(({ status, author }) => author === this.author.userChatId && 'read' !== status))
        .map(({ id }) => id);
      unread && unread.length && this.messagesStatus('read', unread);
    },

    scroll(event) {
      if (
        thresholdCheck(this.threshold, 0, event.target.scrollTop) &&
        (this.messages.paginator && this.messages.paginator.hasNext()) &&
        !this.isLoading
      ) {
        this.paginateMessages(this.messages.paginator.next());
      }
      this.scroller.updatePosition();
    },

    pullMessages(meta) {
      ChatMechanicsMixin.methods.pullMessages.call(this, meta).then(() => {
        this.readMessages();
        this.$nextTick(() => this.scroller.toLast());
      });
    },

    sendMessage() {
      ChatMechanicsMixin.methods.sendMessage.apply(this, arguments);
      this.scroller.toBottom();
    },

    handleTyping() {
      this.$emit('typing');
    },

    handleInput(text) {
      this.text = text;
    },
  },
};
