<template lang="pug">
include /mixins
+b.language-list
  +e.A.item(
    v-for="item in items"
    :href="getLanguageLink(item.value)"
    :class="{'is-active': item.value === language}"
  )
    +e.SPAN.content {{ item.title }}
</template>

<script>
import { prefixLanguage, CURRENT_LANGUAGE } from '@/js/utils/urls';

export default {
  data() {
    return {
      language: CURRENT_LANGUAGE,
      items: [
        { title: this._('UK'), value: 'uk' },
        { title: this._('RU'), value: 'ru' },
        { title: this._('EN'), value: 'en' },
      ],
    };
  },

  methods: {
    getLanguageLink(language) {
      return prefixLanguage(window.location.href, { language })
    },
  },
};
</script>
