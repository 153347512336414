/* eslint-disable  */
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { Ukrainian } from 'flatpickr/dist/l10n/uk.js'
import {
  getEventsList,
  getDayEvent,
} from '@api/base.service.js';

export function newCalendar() {
  const calendar = document.getElementById('calendar')
  if (calendar) {
    let request = {}
    let dates = {}
    const dayData = {}
    let instanceData = function () {}
    const subMenu = document.getElementById('calendar-submenu')
    const joinBtn = document.getElementById('training-join-btn')
    // let clonedSubMenu = subMenu?.cloneNode(true)
    let selectedDate = ''
    let currentClonedSubMenu = ''

    const elementsId = {
      title: '#calendar-submenu-title',
      trainingDate: '#calendar-submenu-date',
      trainingTime: '#calendar-submenu-time',
      trainingDuration: '#calendar-submenu-duration',
      description: '#calendar-submenu-description',
      accessSubscriptionType: '#calendar-submenu-tag',
    }
    function formatDate(date) {
      const d = new Date(date);
      let month = `${ d.getMonth() + 1}`;
      let day = `${ d.getDate()}`;
      const year = d.getFullYear();

      if (2 > month.length) { month = `0${ month}`; }
      if (2 > day.length) { day = `0${ day}`; }

      return [year, month, day].join('-');
    }
    function setTextContent(elementId, content, sMenu) {
      const element = sMenu.querySelector(elementId)
      element.innerHTML = content
    }
    function setAvatar(content, sMenu) {
      const element = sMenu.querySelector('#calendar-submenu-avatar')
      element.setAttribute('src', content)
    }
    function setBtnLink(content) {
    // let elementBtn = clonedSubMenu.querySelector('#calendar-submenu-button')
    // elementBtn.setAttribute('href', content )
    }
    function btnEventDispatcher(btn) {
      btn.__vue__.$el.lastElementChild.dispatchEvent(new Event('click'))
    }

    function getDayData(date, target, sMenu) {
      const dateRequest = {
        training_date: date,
      }
      getDayEvent.execute(dateRequest).then(res => {
        if (200 === res.code) {
          const info = {
            signUp: res.data.items[0].isTrainingparticipant,
            id: res.data.items[0].id,
            title: res.data.items[0].title,
            date: `${res.data.items[0].trainingDate.day} ${res.data.items[0].trainingDate.monthYear}`,
            time: res.data.items[0].trainingTime,
            isPaste: res.data.items[0].isPast,
            haveAccess: res.data.items[0].subscriptionAccess,
          }
          // переноси сюда создание клона
          const [currentJoinsBtn] = sMenu.getElementsByClassName('js-train');
          const joinBtn = document.getElementById('training-join-btn')
          // joinBtn.setAttribute('src', content )
          joinBtn.__vue__.TrainingSignUpModal.props.info = info
          joinBtn.__vue__.haveAccess = info.haveAccess
          joinBtn.__vue__.buyModalConfig.props.isAuth = true

          for (const prop in elementsId) {
            if ('trainingDate' === prop) {
              setTextContent(elementsId[prop], `${res.data.items[0][prop].day} ${res.data.items[0][prop].monthYear}`, sMenu)
            } else {
              setTextContent(elementsId[prop], res.data.items[0][prop], sMenu)
            }
          }
          target.appendChild(currentClonedSubMenu)
          const [jBtn] = target.getElementsByClassName('js-signup')
          if (info.isPaste) {
            jBtn.textContent = currentJoinsBtn.dataset.past
            jBtn.classList.remove('btn-red')
            jBtn.classList.add('btn-dark-trans')
            jBtn.classList.add('none_events')
          } else if (info.signUp) {
            jBtn.textContent = currentJoinsBtn.dataset.inactive
            jBtn.classList.remove('btn-red')
            jBtn.classList.add('btn-dark-trans')
          } else {
            jBtn.textContent = currentJoinsBtn.dataset.active
            jBtn.classList.remove('btn-dark-trans')
            jBtn.classList.add('btn-red')
          }
          jBtn.removeEventListener('click', () => btnEventDispatcher(joinBtn), false)
          jBtn.addEventListener('click', () => btnEventDispatcher(joinBtn), { once: true })
          document.getElementById('calendar-submenu-close')?.addEventListener('click', event => {
            if (selectedDate) {
              selectedDate.classList.remove('focused')
              selectedDate.blur()
              selectedDate.removeChild(currentClonedSubMenu)
              selectedDate = ''
            }
          })
          setAvatar(res.data.items[0].coach.avatar, sMenu)
        }
      })
    }

    function getData(selectedDates, dateStr, instance) {
      instanceData = instance
      const days = instance.l10n.daysInMonth[instance.currentMonth]
      const dateFrom = `1.${instance.currentMonth + 1}.${instance.currentYear}`
      const dateTo = `${days}.${instance.currentMonth + 1}.${instance.currentYear}`
      request = {
        date_from: dateFrom,
        date_to: dateTo,
      }
    }
    function submenuClose(sMenu) {
      document.addEventListener('click', event => {
        if (event.target !== sMenu && selectedDate) {
          selectedDate.removeChild(selectedDate.childNodes[1])
          selectedDate.classList.remove('focused')
          selectedDate = ''
        }
      })
    }

    function clickDisabler(dObj, dStr, fp, dayElem) {
      fp.daysContainer.addEventListener('click', event => {
        event.stopImmediatePropagation()
        const [btn] = document.getElementsByClassName('js-signup')
        if ('' !== currentClonedSubMenu) {
          if (!currentClonedSubMenu.contains(event.target) && selectedDate) {
            selectedDate.removeChild(currentClonedSubMenu)
            selectedDate.classList.remove('focused')
            selectedDate = ''
            btn.removeEventListener('click', () => btnEventDispatcher(btn), true)
          }
        }
        if (event.target.classList.contains('selected')) {
          const clonedSubMenu = subMenu?.cloneNode(true)
          currentClonedSubMenu = clonedSubMenu
          event.target.classList.add('focused')
          selectedDate = event.target
          getDayData(formatDate(event.target.dateObj), event.target, currentClonedSubMenu)
        }
      })
    }
    submenuClose(currentClonedSubMenu)
    getEventsList.execute(request).then(res => {
      if (200 === res.code) {
        const config = {
          inline: true,
          mode: 'multiple',
          altInput: false,
          allowInput: true,
          allowInvalidPreload: true,
          onReady: getData,
          onMonthChange: getData,
          onChange: getData,
          onDayCreate: clickDisabler,
          currentMonth: 0,
        }
        if ('ru' === calendar?.getAttribute('lang')) {
          config.locale = Russian
        }
        if ('uk' === calendar?.getAttribute('lang')) {
          config.locale = Ukrainian
        }
        config.id = res.data.items.map(element => element.id);
        config.defaultDate = res.data.items.map(element => element.trainingDate);
        dates = res.data.items
        flatpickr('input[type=calendar]', config).jumpToDate(new Date())
      }
    });
  }
}
