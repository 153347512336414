import LimitPaginator from './LimitPaginator.js';

/**
 * A cursor pagination based style. For example:
 *
 * http://api.example.org/accounts/?limit=100
 * http://api.example.org/accounts/?startCursor=eyJzb21lIjoidGhpbmcifQ==&limit=100
 *
 * @export
 * @class CursorPaginator
 * @extends {LimitPaginator}
 */
export default class CursorPaginator extends LimitPaginator {
  hasNext() {
    return this.info.hasNext;
  }

  hasPrevious() {
    return this.info.hasPrevious;
  }

  next() {
    return {
      after: this.info.endCursor,
      limit: this.limit,
    };
  }

  previous() {
    return {
      before: this.info.startCursor,
      limit: this.limit,
    };
  }
}
