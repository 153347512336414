<template lang="pug">

component(
  :is="wrappedComponent",
  v-bind="$attrs",
  v-on="$listeners",
  :slotAreas="slotAreasMerged"
)
  template(slot="emoji-picker-clicker", slot-scope="clicker")
    slot(name="emoji-picker-clicker")
      button.stacked-elements__element.control-button(
        @click.prevent="toggleWindow"
      )
        span.mdi.mdi-sticker-emoji(name="mood")

  template(slot="field", slot-scope="field")
    slot(
      name="field"
      :handleTyping='field.typingHandler'
      :sendText='field.sendHandler'
      :handleInput='($event) => field.handler($event.target.value)'
      :text='field.text'
    )
      textarea.stacked-elements__element.stacked-elements__element--bound_start.control-input(
        ref="field",
        :value="field.text",
        @input="field.handler($event.target.value)"
        @keyup="field.typingHandler",
        @keyup.ctrl.enter="field.sendHandler",
        rows="1"
      )
  //- template(#emoji-picker-window="{window}")
    wds-dialog-window(
      ref="window",
      dismissible="",
      :opened="window && window.window.opened || false",
      @input="window && window.handler || handler",
    )
      slot(name="emoji-picker")
        wds-emoji-picker(style="height: 200px", @input="addEmoji")

  //- slot(:name="slot", :slot="slot", v-for="slot in namedSlots()")
  template(
    v-for="(_, slot) of $scopedSlots"
    v-slot:[slot]="scope"
  )
    slot(
      :name="slot"
      v-bind="scope"
    )
</template>

<script>

import MessagingInjectorMixin from '../../../utils/mixins/MessagingInjectorMixin';
import { SlotAreasMergerMixin } from '../../../utils/slot-areas';

const currentSlotAreas = {
  clickers: ['emoji-picker-clicker'],
  dialogWindows: ['emoji-picker-window'],
};

export default {
  mixins: [SlotAreasMergerMixin, MessagingInjectorMixin],

  currentSlotAreas,

  props: ['wrappedComponent', 'slots'],

  methods: {
    toggleWindow() {
      this.$refs.window.toggle();
    },

    addEmoji(item) {
      const { field } = this.$refs;
      const position = field.selectionEnd;
      const { value } = field;

      field.value = value.slice(0, position) + item + value.slice(position);
      field.dispatchEvent(new Event('input'));
    },
  },
};

</script>
