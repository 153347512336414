// import VueRouter from 'vue-router'
import { createRoutes as createCabinet } from '@app/Cabinet/router'

import { prefixLanguage } from './js/utils/urls';
// import { createRoutes as createCabinetManager } from '@components/Manager/router'

// import { createRoutes as createCabinetBuyer } from '@components/Buyer/router'
// const Clients = () => import('');
// const Managers = () => import('../components/Forms/AuthForm.vue');

// export default new VueRouter({
//   routes: [
//     {
//       path: '/',
//       name: 'Clients',
//       component: Clients,
//     },
//     {
//       path: '/managers',
//       name: 'Managers',
//       component: Managers,
//     },
//   ],
// })

// import { createRoutes as createCabinetForBuyer } from '@app/Buyer/router'
import { prefixRoutes } from './js/Routing/index';

export function createRoutes(options) {
  return [
    prefixRoutes('/cabinet/', createCabinet(options)),
    // prefixRoutes('/manager/', createCabinetManager(options)),
    // prefixRoutes('/buyer/', createCabinetBuyer(options)),

    // route('/404/', Route404, '404'),
    // { path: '*', redirect: { name: '404' } },
  ];
}

export function scrollBehavior(to, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
}

export function createRouter(options = {}) {
  const { Router, Vue } = options;
  const router = new Router({
    mode: 'history',
    base: prefixLanguage('/'),
    scrollBehavior,
    routes: createRoutes({ Router, Vue }),
  });

  return router;
}
