/* eslint-disable no-shadow */
export function now() {
  return new Date();
}

export function comparableDate(date) {
  return new Date(date).getTime();
}

export function date(datetime) {
  const date = new Date(datetime);
  date.setHours(0, 0, 0, 0);

  return date;
}
