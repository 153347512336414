import {
  createResource, createRemover,
  postResource,
  baseResource,
  patchResource,
} from '@resource/resource';
import { prefixAPI } from '@resource/api';
import { prefixLanguage } from '@/js/utils/urls';
import {
  friendlyUrlGenerator,
} from '@aspectus/vue-friendly'

const FORUM_TOPIC_CREATE_URL = prefixAPI('forum/topic{/slug}/create/');
const FORUM_TOPIC_DELETE_URL = prefixAPI('forum/topic/delete{/id}/');
// const FORUM_LIST_URL = prefixAPI('forum{/label}/{?search,order,limit,offset}')
const FORUM_LIST_URL = prefixAPI('forum{/label}/{;filters*}/{?limit,offset}')
const FORUM_TOPIC_UPDATE = prefixAPI('forum/topic/update/{id}/')

// const AJAX_FORUM_LIST_URL = prefixLanguage('/forum/{label}/ajax/filters/{;filters*}{?limit,offset}')

export const topicCreateResource = createResource(FORUM_TOPIC_CREATE_URL, postResource);
export const topicDeleteResource = createRemover(FORUM_TOPIC_DELETE_URL);
export const forumListResource = createResource(FORUM_LIST_URL)
export const topicUpdateResource = createResource(FORUM_TOPIC_UPDATE, patchResource)

// export const forumTopicsListAjax = createReceiver(AJAX_FORUM_LIST_URL)

const order = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/forum/')
const FILTERS_LIST_URL_POSTFIX = '/ajax/filters/'
export const forumTopicsListAjax =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)
