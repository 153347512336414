<template lang="pug">
include /mixins
+b.course-info__videos-checkbox
  +b.INPUT.course-info__videos-checkbox-input(
    type='checkbox'
    v-model.props="passed"
    :checked='passed'
    @click.prevent="passe()"
    )
</template>

<script>
import {
  viewVideoToggle,
} from '@api/courses.service.js';
import { modalOpener } from '@utils/helpers'

const loginForm = () => import('../Modals/Auth/Login.vue');
export default {
  name: 'ViewVideoToggle',
  props: ['id', 'isPassed', 'currentUrl'],
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      passed: 'false',
    };
  },
  methods: {
    modalOpener,
    passe() {
      if (!this.isAuth) {
        this.passed = false
        this.modalOpener({
          props: {
            isComponent: loginForm,
            next: window.location.pathname,
          },
          config: {
            width: 345,
            height: 'auto',
            adaptive: true,
            scrollable: true,
          },
        })
        return null
      }
      return viewVideoToggle.execute({ videocourse_id: this.id }).then(res => {
        if (202 === res.code) {
          this.passed = res.data.item.passed
          if (this.currentUrl) {
            window.location = this.currentUrl
          } else {
            this.$emit('finish')
          }
        }
      });
    },
  },
  mounted() {
    this.passed = this.isPassed
  },
};
</script>
