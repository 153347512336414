<template lang="pug">
include /mixins
+b.DIV(@click.prevent="copyToClipboard(text)")
  slot

</template>

<script>
export default {
  name: 'CopiToClip',
  props: ['text', 'pos'],
  data() {
    return {
      toastText: this._('Ссылка скопирована в буфер обмена'),
    };
  },
  methods: {
    copyToClipboard(data) {
      this.$copyToClipboard(data)
      this.$toasted.show(this.toastText, {
        theme: 'outline',
        position: this.pos || 'top-left',
        duration: 2500,
      });
    },
  },
};
</script>
