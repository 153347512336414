/* eslint-disable */
import { DataFeedInterface } from '@webcase/vue-chat/src/DataFeed/Interface';
import { now } from '@webcase/vue-chat/src/utils/date';
import sanitizeHtml from 'sanitize-html'
import { v4 as uuidv4 } from 'uuid';
import {
  // onlineSet,
  // createRoom,
  getRoomsList,
  getMessagesList,
  createMessage,
  readMessage,
  // deleteMessage,
  // getUnreadCount,
  // updateMessage,
} from '@api/chat.service';
import instant from './centrifugo';

import {
  MESSAGE_NEW,
  // MESSAGE_RECEIVED,
  MESSAGE_UPDATED,
  // TYPING_STARTED,
  // TYPING_STOPPED,
  ROOM_NEW,
  ROOM_UPDATED,
  // ROOM_REMOVED,
  // AUTHOR_UPDATED,
  // GLOBAL_UNREAD,
} from './const.js'


const genId = (counter => () => counter++)(1000000000)

function chatAuthors(chats, id, callback) {
  const chat = chats.find(x => x.id === id);
  Object.values(chat.authors).forEach((author, i) => {
    callback.call(this, author, i);
  });
}

function migrateRoomToChat(room) {
  const {
    id, unread_messages_count , preview, 
    updated_at, created_at, interlocutor, members, title, meta, last_message,
  } = room
  const chat = {}

  chat.createdAt = created_at
  chat.updatedAt = updated_at
  chat.id = id 
  chat.unread = unread_messages_count
  chat.title = title
  chat.preview = preview
  chat.members = members
  chat.meta = meta

  if (last_message) {
    chat.latest = last_message
    
    chat.latest.authorId = members.find(({ author: { id }}) => id === last_message.author)?.author?.remote_user?.id
    if (!chat.latest.authorId) chat.latest = null
  }
  chat.authors = members.reduce((acc, member, idx) => {
    const { author, author: { remote_user: { avatar, username, id } } } = member

    acc[id] = {
      id,
      userChatId: author.id,
      avatar,
      displayName: username || 'bbb',
    }
    return acc 
  }, {})

  return chat;
}

function migrateMessage(message) {
  const result = { ...message }
  result.chatId = message.room;
  if (message.author) {
    result.author = message.author.remote_user
    result.authorId = message.author?.remote_user?.id
    result.author.status = message.author.status
    result.author.avatar = { source: { url: result.author.avatar } }
    result.author.displayName = message.author.remote_user.fio_name
    result.author.authorMessageId = message.author.id
  }
  result.statuses = message.statuses.map(status => {
    return { ...status, author: status.author.id }
  })
  result.updatedAt = message.updated_at
  result.createdAt = message.created_at
  result.type = message.type

  return result
}

function migrateEventData(eventData) {
  eventData.room = eventData.chatId;
  eventData.id = uuidv4();
  // eventData.text = eventData.body.content
  // eventData.file = eventData.body.source && eventData.body.source.file
  // eventData.id = eventData.body.file && eventData.body.file.id
  eventData.meta = {}
  delete eventData.chatId
  return eventData;
}

function migratePagination(info) {
  return {
    endCursor: info.endCursor || info.end_cursor,
    startCursor: info.startCursor || info.start_cursor,
    hasNext: info.hasNext || info.has_next,
    hasPrevious: info.hasPrevious || info.has_previous,
    limit: info.limit,
  }
}

const EVENT_HANDLER = {
  [MESSAGE_NEW]: migrateMessage,
  [ROOM_UPDATED]: migrateRoomToChat,
  [ROOM_NEW]: migrateRoomToChat,
}

export default class ChatFeed extends DataFeedInterface {
  constructor() {
    super();
    if (window.IS_AUTHENTICATED) {
      (async () => {
        await instant.getToken()
        await instant.connect();
      })();
      instant.onMessage = this.handleEvent.bind(this);
    }
  }

  encoder(end) {
    const date = new Date(end.replace(/ /g, "T")).toISOString()
    const result = `p=${date}`
    return btoa(result)
  }

  handleEvent(event) {
    const { eventClass, data } = event;
    let body = data;
    const type = eventClass;

    if (EVENT_HANDLER[eventClass]) {
      body = EVENT_HANDLER[eventClass](body)
    }
    if (eventClass === MESSAGE_UPDATED) {
      body = body.map(migrateMessage);
    }

    this.emitter.emit(type, { type, body });
  }

  receiveHistory({ endCursor, chatId: id, limit, offset } = {}) {
    const cursor = this.encoder(endCursor)
    return getMessagesList.execute({ limit, offset, cursor, id }).then(({ data: { item: { items, pagination } }}) => ({
      data: items.map(migrateMessage),
      meta: migratePagination(pagination),
    }));
  }

  receiveChats({ endCursor, limit, offset, filters } = {}) {
    const cursor = this.encoder(endCursor)
    return getRoomsList.execute({ limit, offset, cursor, filters }).then(({ data: { items, pagination } }) => ({
      data: items.map(migrateRoomToChat),
      meta: migratePagination(pagination),
    }));
  }

  setConnection(status) {
    this.isOnline = 'online' !== status;
    this.emitter.emit(`connection:${status}`, {
      type: `connection:${status}`,
      createdAt: now().toISOString(),
      body: {},
    });
  }

  sendMessage(message) {
    if (message.body.content) {
      // protect from scripts
      message.body.content = sanitizeHtml(message.body.content)
    }
    return createMessage.execute({}, migrateEventData(message))
      .catch(() => {
        console.log('failed');
        this.setConnection('offline');
        message.createdAt = now().toISOString();
        message.updatedAt = message.createdAt;
        message.status = { type: 'failed', updatedAt: message.createdAt };
        this.emitter.emit('chat:message:failed', {
          type: 'chat:message:failed',
          body: message,
        });
      });
  }

  sendEvent(type, body) {
    if ('chat:message:read' === type) {
      readMessage.execute({}, { messages: body.messagesIds });
    }
  }

  // sendFile(file) {
  //   let timeout = null;
  //   emitter.on('cancel', x => console.log(clearTimeout(timeout)));
  //   let url = null;

  //   for (let index = 0; 301 > index; index++) {
  //     (i => setTimeout(
  //       () => emitter.emit('progress', { loaded: i / 3, total: 100 }),
  //       i * 10
  //     ))(index);
  //   }

  //   const reader = new FileReader();
  //   reader.onload = e => {
  //     url = e.target.result;
  //   };
  //   reader.readAsDataURL(file);

  //   timeout = setTimeout(() => emitter.emit('success', {
  //     id: 1,
  //     file: url,
  //     name: file.name,
  //     size: file.size,
  //     source: {
  //       url,
  //     },
  //   }), 3000);

  //   return emitter;
  // }
}
