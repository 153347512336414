<script>

import { WindowVisibility } from '../../utils/window-visibility';
import FeedEventsHandlerMixin from '../../utils/mixins/FeedEventsHandlerMixin';
import MessagingInjectorMixin from '../../utils/mixins/MessagingInjectorMixin';

/**
 * Component that plays provided audio when event, that it was
 * subscribed on fires.
 *
 * Audio will be played only if current tab is not active.
 *
 * TODO:
 *
 * - [ ] Make sound only from one tab, if there are more.
 *
 * @version 0.9.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsChatAudio',

  mixins: [FeedEventsHandlerMixin, MessagingInjectorMixin],

  feedHandlers: [
    {
      event: 'chat:message:new',
      handler(event) {
        if (!this.subscribed('chat:message:new')) return;
        if (this.visibility.visible) return;
        if (this.userId === event.body.authorId) return;

        this.sound();
      },
    },
    {
      event: 'chat:message:failed',
      handler(event) {
        if (!this.subscribed('chat:message:failed')) return;
        if (this.userId === event.body.authorId) return;

        this.sound();
      },
    },
  ],

  props: {
    /**
     * User identifier, to determine whether there was event provided
     * by him, or any other author.
     */
    userId: [Number, String],

    /**
     * Events on which audio must be played.
     *
     * Now there's only:
     *
     * - `chat:message:new` :: Plays on a new message receive.
     */
    on: {
      type: Array,
      required: true,
    },

    /**
     * Url to an audio file that will be played on event fire.
     */
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      visibility: new WindowVisibility(),
      audio: { then() {} },
    };
  },

  watch: {
    url: 'makeAudio',
  },

  mounted() {
    this.visibility.bind();
    this.makeAudio();
  },
  methods: {
    makeAudio() {
      if (!this.url) {
        this.audio = Promise.reject();
      }

      this.audio = new Promise(resolve => {
        const audio = new Audio();
        audio.addEventListener('canplaythrough', () => resolve(audio));
        audio.preload = 'auto';
        audio.src = this.url;
        audio.load();
      });
    },

    subscribed(event) {
      return this.on.includes(event);
    },

    sound() {
      this.audio.then(audio => audio.play());
    },
  },
  // Will be rendered into comment.
  render() { return null; },
};

</script>

<docs>
</docs>
