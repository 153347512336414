<script>

import { modalOpener } from '@utils/helpers'

const BuySubscription = () => import('../Modals/Interaction-modals/BuySubscription');
const LogIn = () => import('../Modals/Auth/Login');

export default {
  name: 'UpdateSubscription',
  props: ['title', 'requiredSubscription'],
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      buyModalConfig: {
        props: {
          isComponent: BuySubscription,
          title: this.title,
          subscriptionType: this.requiredSubscription,
          variables: {
            theme: 'dark',
            isAuth: false,
          },
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: 593,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
        // actor: this.openAuthModal,
      },
    };
  },
  mounted() {
  },
  methods: {
    modalOpener,
    open() {
      // eslint-disable-next-line no-unused-expressions
      this.isAuth ? this.modalOpener(this.buyModalConfig) : this.authModalOpener()
    },
    authModalOpener() {
      this.modalOpener({
        props: {
          isComponent: LogIn,
        },
        // classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '345',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      open: this.open,
    })
  },
};
</script>
