/**
 * @mixin
 */
export default {
  inject: {
    service: { from: 'chatMessagingService' },
  },

  data() {
    return {
      feed: this.service.feed,
    };
  },

  watch: {
    'service.feed': {
      immediate: true,
      handler(value) {
        this.feed = value;
      },
    },
  },

  methods: {
    getLiveChats(chats) {
      return chats.map(({ id }) => this.service.chats[id]);
    },

    getLiveAuthors(authors) {
      return authors.map(({ id }) => this.service.authors[id]);
    },
  },
};
