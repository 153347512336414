import View from './View'
import ForumFilter from './ForumFilter';
import ForumSearch from './ForumSearch';
import TopicTemplate from './TopicTemplate.vue';

export default function install(Vue) {
  Vue.component('forum-catalog', View)
  Vue.component('ForumFilter', ForumFilter);
  Vue.component('ForumSearch', ForumSearch);
  Vue.component('TopicTemplate', TopicTemplate);
}
