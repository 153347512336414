/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable class-methods-use-this */
/**
 * Python-like api for class creation.
 *
 * Try not to use `constructor`, use `init` method instead.
 *
 * A little bit easier inheritance, because in an init method you may
 * call super in any order. And it's also shorter to write.
 *
 * @export
 * @class Initable
 */
export class Initable {
  constructor() {
    this.init.apply(this, arguments);
  }

  init() {

  }
}
