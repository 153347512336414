/**
 * Subscription events list schema.
 *
```yml
type: array
items:
  type: object
  properties:
    event
      type: string
      description: Event name, to subscribe on.
    handler:
      type: Function
      description: Event handler function.
```
 */

/**
 * Subscribe function.
 *
 * @param {EventEmitter} emitter Event emitter to subscribe on.
 * @param {Array} handlers Array of event handlers.
 * @param {*} context Element, that will be the calling context for an
 *  event handler.
 * @returns {Array} Binded handlers.
 */
export function subscribe(emitter, handlers, context = null) {
  if (!emitter || !emitter.on) return handlers;

  const binded = !context ? handlers : handlers.map(
    ({ event, handler }) => ({ event, handler: handler.bind(context) })
  );

  binded.forEach(({ event, handler }) => {
    emitter.on(event, handler);
  });

  return binded;
}

/**
 * Unsubscribe function.
 *
 * @param {EventEmitter} emitter Event emitter to unsubscribe from.
 * @param {Array} handlers Array of event handlers.
 */
export function unsubscribe(emitter, handlers) {
  if (!emitter || !emitter.on) return;

  handlers.forEach(({ event, handler }) => {
    emitter.removeListener(event, handler);
  });
}
