import { createResource, postResource, patchResource } from '@resource/resource';
import { prefixAPI } from '@resource/api';

const USER_PROFILE_URL = prefixAPI('account/');
const USER_PROFILE_UPDATE_URL = prefixAPI('account/change/')
const USER_AVATAR_CHANGE_URL = prefixAPI('account/avatar/change/')
const USER_PASS_CHANGE_URL = prefixAPI('account/password-change/')
const USER_EMAIL_CHANGE_URL = prefixAPI('account/email-change/')
const USER_REFERRALS_LIST_URL = prefixAPI('account/referrals/{?limit,offset}')
const GET_TIMEZONES_URL = prefixAPI('account/timezones/')

export const getProfileData = createResource(USER_PROFILE_URL);
export const updateProfileData = createResource(USER_PROFILE_UPDATE_URL, patchResource);
export const userAvatarChange = createResource(USER_AVATAR_CHANGE_URL, patchResource);
export const userPassChange = createResource(USER_PASS_CHANGE_URL, postResource);
export const userEmailChange = createResource(USER_EMAIL_CHANGE_URL, postResource);
export const userReferralList = createResource(USER_REFERRALS_LIST_URL);
export const getTimeZones = createResource(GET_TIMEZONES_URL);
