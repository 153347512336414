<template lang="pug">
include /mixins
+b.profile-time-wrapper
  +b.time-label Укажите часовой пояс*
  +b.D-CONTROL-MULTISELECT(
        label='Укажите часовой пояс*'
        name='mts1',
        v-model='checked',
        :options='options'
        placeholder='Выберите часовой пояс',
        :custom-label='getTitle'
      )

</template>

<script>
export default {
  data() {
    return {
      name: 'VideosFilter',
      checked: '',
      options: [
        {
          id: 'available',
          props: {
            title: this._('Available'),
          },
        },
        {
          id: 'locked',
          props: {
            title: this._('Locked'),
          },
        },
        {
          id: 'unavailable',
          props: {
            title: this._('Unavailable'),
          },
        },
        {
          id: 'out_of_stock',
          props: {
            title: this._('Out of stock'),
          },
        },
      ],
    };
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send() {
      // return loginResource.execute({}, this.form).then(result => {
      //   window.location.pathname = result.data.item.redirect;
      // });
    },
    getTitle({ props }) {
      return props.title;
    },
    // updateValidator(errors) {
    //   const parsed = defaultValidatorErrorsParser(errors);
    //   console.log(parsed);
    //   this.nonFieldErrors = parsed.nonFieldErrors;
    //   this.$refs.validator.setErrors(parsed);
    // },
  },
};
</script>
