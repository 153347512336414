import {
  route,
} from '../../Routing/index.js'

const Profile = () => import('./Profile/Profile.vue')
const userCourses = () => import('./Courses/Courses.vue')
const userCourse = () => import('./Courses/Course.vue')
const userTrainings = () => import('./Trainings/Trainings.vue')
const myLibrary = () => import('./Library/Library.vue')
const myFavorites = () => import('./Subscriptions/MyFavorites.vue')
const mySubscriptions = () => import('./Subscriptions/MySubscription.vue')
const myTransactions = () => import('./Transactions/Transactions.vue')
const myTopics = () => import('./Topics/Topics.vue')
const myOrder = () => import('./Payment/Order.vue')
const PaypalPayment = () => import('./Payment/PaypalPayment.vue')
const ReplenishmentPaypalPayment = () => import('./Payment/ReplenishmentPaypalPayment.vue')
const StripePayment = () => import('./Payment/StripePayment.vue')
const ReplenishmentSkrillPayment = () => import('./Payment/ReplenishmentSkrillPayment.vue')
const SkrillPayment = () => import('./Payment/SkrillPayment.vue')
const ReplenishmentStripePayment = () => import('./Payment/ReplenishmentStripePayment.vue')
const PaymentSuccessful = () => import('./Payment/PaymentSuccessful.vue')
const Notifications = () => import('./Notifications/Notifications.vue')
const ContactUs = () => import('./Contact/ContactUs.vue')
const Messages = () => import('./Messages/Messages.vue')

const PROFILE_DATA = 'profile/'
const USER_COURSES_DATA = 'my-courses/'
const USER_COURSE_DATA = 'my-course/:id'
const USER_TRAININGS_LIST = 'my-trainings/'
const MY_FAVORITES_LIST = 'my-favorites/'
export const MY_SUBSCRIPTIONS_LIST = 'my-subscriptions/'
export const MY_TRANSACTIONS_LIST = 'my-transactions/'
const MY_LIBRARY_LIST = 'my-library/'
const MY_TOPICS_LIST = 'my-topics/'
const NEW_ORDER = 'order/'
const PAYMENT_SUCCESSFUL = 'order/payment-successes/'
const PAYPAL_PAYMENT = 'order/paypal-payment/:id/'
const REPLENISHMENT_PAYPAL_PAYMENT = 'replenishment/paypal-payment/:id/'
const STRIPE_PAYMENT = 'order/stripe-payment/:id/'
const REPLENISHMENT_STRIPE_PAYMENT = 'replenishment/stripe-payment/:id/'
const SKRILL_PAYMENT = '/skrill-payment/:id/'
const REPLENISHMENT_SKRILL_PAYMENT = 'replenishment/skrill-payment/:id/'
const NOTIFICATIONS_LIST = 'notifications/'
const CONTACT_US = 'contact-us-form/'
const MESSAGES_LIST = 'messages/'

export const PROFILE_DATA_NAME = 'user:profile'
export const USER_COURSES_DATA_NAME = 'user:courses'
export const USER_COURSE_DATA_NAME = 'user:course:id'
export const USER_TRAININGS_LIST_NAME = 'user:trainings'
export const MY_FAVORITES_LIST_NAME = 'user:my-favorites'
export const MY_SUBSCRIPTIONS_LIST_NAME = 'user:my-subscriptions'
export const MY_TRANSACTIONS_LIST_NAME = 'user:my-transactions'
export const MY_LIBRARY_LIST_NAME = 'user:my-library'
export const MY_TOPICS_LIST_NAME = 'user:my-topics'
export const NEW_ORDER_NAME = 'user:order'
export const PAYMENT_NAME = 'user:payment'
export const PAYMENT_SUCCESSFUL_NAME = 'user:payment-successes'
export const PAYPAL_PAYMENT_NAME = 'user:paypal-payment'
export const REPLENISHMENT_PAYPAL_PAYMENT_NAME = 'user:replenishment:paypal-payment'
export const STRIPE_PAYMENT_NAME = 'user:stripe-payment'
export const REPLENISHMENT_STRIPE_PAYMENT_NAME = 'user:replenishment:stripe-payment'
export const SKRILL_PAYMENT_NAME = 'user:skrill-payment'
export const REPLENISHMENT_SKRILL_PAYMENT_NAME = 'user:replenishment:skrill-payment'
export const NOTIFICATIONS_LIST_NAME = 'user:notifications'
export const CONTACT_US_NAME = 'user:contact-form'
export const MESSAGES_LIST_NAME = 'user:messages'

export function createRoutes() {
  return [
    route(PROFILE_DATA, Profile, PROFILE_DATA_NAME, { meta: { current: 'profile' } }),
    route(USER_COURSES_DATA, userCourses, USER_COURSES_DATA_NAME, { meta: { current: 'user:courses' } }),
    route(USER_COURSE_DATA, userCourse, USER_COURSE_DATA_NAME, { meta: { current: 'user:course' } }),
    route(USER_TRAININGS_LIST, userTrainings, USER_TRAININGS_LIST_NAME, { meta: { current: 'user:trainings' } }),
    route(MY_FAVORITES_LIST, myFavorites, MY_FAVORITES_LIST_NAME, { meta: { current: 'user:my-favorites' } }),
    route(MY_SUBSCRIPTIONS_LIST, mySubscriptions, MY_SUBSCRIPTIONS_LIST_NAME, { meta: { current: 'user:my-subscriptions' } }),
    route(MY_LIBRARY_LIST, myLibrary, MY_LIBRARY_LIST_NAME, { meta: { current: 'user:my-library' } }),
    route(MY_TOPICS_LIST, myTopics, MY_TOPICS_LIST_NAME, { meta: { current: 'user:my-topics' } }),
    route(NEW_ORDER, myOrder, NEW_ORDER_NAME, { meta: { current: 'user:new-order' } }),
    route(PAYMENT_SUCCESSFUL, PaymentSuccessful, PAYMENT_SUCCESSFUL_NAME, { meta: { current: 'user:payment-success' } }),
    route(PAYPAL_PAYMENT, PaypalPayment, PAYPAL_PAYMENT_NAME, { meta: { current: 'user:paypal-payment' } }),
    route(REPLENISHMENT_PAYPAL_PAYMENT, ReplenishmentPaypalPayment, REPLENISHMENT_PAYPAL_PAYMENT_NAME, { meta: { current: 'user:paypal-payment' } }),
    route(STRIPE_PAYMENT, StripePayment, STRIPE_PAYMENT_NAME, { meta: { current: 'user:stripe-payment' } }),
    route(REPLENISHMENT_STRIPE_PAYMENT, ReplenishmentStripePayment, REPLENISHMENT_STRIPE_PAYMENT_NAME, { meta: { current: 'user:stripe-payment' } }),
    route(SKRILL_PAYMENT, SkrillPayment, SKRILL_PAYMENT_NAME, { meta: { current: 'user:skrill-payment' } }),
    route(REPLENISHMENT_SKRILL_PAYMENT, ReplenishmentSkrillPayment, REPLENISHMENT_SKRILL_PAYMENT_NAME, { meta: { current: 'user:skrill-payment' } }),
    route(MY_TRANSACTIONS_LIST, myTransactions, MY_TRANSACTIONS_LIST_NAME, { meta: { current: 'user:my-transactions' } }),
    route(NOTIFICATIONS_LIST, Notifications, NOTIFICATIONS_LIST_NAME, { meta: { current: 'user:notifications' } }),
    route(CONTACT_US, ContactUs, CONTACT_US_NAME, { meta: { current: 'user:contact-form' } }),
    route(MESSAGES_LIST, Messages, MESSAGES_LIST_NAME, { meta: { current: 'user:messages' } }),

    { path: '', redirect: { name: PROFILE_DATA_NAME } },

  ]
}
