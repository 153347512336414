<script>

import { createPurchase } from '@api/purchase.service'
import { modalOpener } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';
import { prefixLanguage } from '@/js/utils/urls';

const loginForm = () => import('../Modals/Auth/Login.vue');
const AVAILABLE_OBJECT_TYPE_CHOICE = {
  article: 'articles.article',
  pdfarticle: 'articles.pdfarticle',
  video: 'videos.video',
  course: 'courses.course',
  simulation: 'simulations.simulation',
  subscriptionperiodvariant: 'subscription.subscriptionperiodvariant',
}
export default {
  name: 'purchase-item',
  mixins: [FormMixin],
  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    this.isAuth = window.IS_AUTHENTICATED
    return {
      nonFieldErrors: null,
      data: {
        contentType: AVAILABLE_OBJECT_TYPE_CHOICE[this.initial.content_type],
        objectId: this.initial.id,
      },
    }
  },
  methods: {
    modalOpener,
    prepareData() {
      if (this.isAuth) {
        this.submit(true, this.data);
      } else {
        this.modalOpener({
          props: {
            isComponent: loginForm,
            next: window.location.pathname,
          },
          config: {
            width: 345,
            height: 'auto',
            adaptive: true,
            scrollable: true,
          },
        })
      }
    },
    send() {
      return createPurchase.execute({}, this.data)
        .then(({ data: { item } }) => {
          if (item) {
            window.location.href = prefixLanguage('/cabinet/order/')
          }
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.modalOpener({
        props: {
          title: this.nonFieldErrors[0],
        },
        classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '593',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      })
      // this.$refs.validator.setErrors(parsed);
    },
  },
  render() {
    return this.$scopedSlots.default({
      purchase: this.prepareData,
      loading: this.isLoad,
      non_field_errors: this.nonFieldErrors,
    })
  },
}
</script>
