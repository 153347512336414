import { createResource, baseResource } from '@resource/resource';
import { friendlyUrlGenerator } from '@utils/utils'

import { prefixAPI } from '@resource/api';
// import {
//   simpleTransformer,
// } from '@resource/transformer'
const order = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const GET_VIDEO_LIST_URL = prefixAPI('video/list/{?search,game_type,coach,video_type,publication_date,table_type,subscription_type,limit,offset}');

const FILTERS_LIST_URL_PREFIX = '/search-ajax/filters'

const GET_CALENDAR_DATES_URL = prefixAPI('trainings/calendar/dates/{?date_from,date_to}')
const GET_DAY_DATA_URL = prefixAPI('trainings/calendar/day/{?training_date}')

export const usersListUrl = friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX], baseResource, order)
export const getVideoList = createResource(GET_VIDEO_LIST_URL);
export const getEventsList = createResource(GET_CALENDAR_DATES_URL);
export const getDayEvent = createResource(GET_DAY_DATA_URL);
