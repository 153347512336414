/* eslint-disable no-underscore-dangle */
import Centrifuge from 'centrifuge';

import Message from './message.js';

export default class Instant {
  constructor(backendUri, websocketsUri, verbose = false) {
    this.csrfToken = '';
    this.channels = new Set();
    this._onMessage = (message) => console.log(JSON.stringify(message, null, "  ")); // eslint-disable-line
    this.backendUri = backendUri;
    this.websocketsUri = websocketsUri;
    this.verbose = verbose;
    this.tempMsg = undefined;
    this.client = new Centrifuge(`${this.websocketsUri}connection/websocket`, {
      subscribeEndpoint: `${this.backendUri}subscribe/`,
      debug: false,
    });
  }

  set onMessage(func) {
    this._onMessage = func;
  }

  async connect(autosub = true) {
    if (autosub) {
      this.subscribeToAllChannels();
    }
    let end;
    const connected = new Promise(resolve => {
      end = resolve;
    });
    this.client.on('connect', () => {
      end(true);
    });
    this.client.connect();
    await connected;
  }

  subscribeToAllChannels() {
    this.channels.forEach(c => {
      // const sub =
      this.client.subscribe(c, message => {
        // console.log(JSON.stringify(message, null, '  '));
        const msg = this.processRawMessage(message.data);
        this._onMessage(msg);
      });
      // sub.on("subscribe", (ctx) => console.log("SUBSCRIPTION", ctx));
    });
  }

  // async login(username, password) {
  //   // console.log('Login');
  //   const payload = {
  //     username,
  //     password,
  //   };
  //   const opts = this.postHeader(payload);
  //   const uri = `${this.backendUri }login/`;
  //   const response = await fetch(uri, opts);
  //   if (!response.ok) {
  //     // console.log('Response not ok', response);
  //     throw new Error(response.statusText);
  //   }
  //       const data = (await response.json()); // eslint-disable-line
  //   this.processResponse(data);
  // }

  async getToken() {
    const opts = this.postHeader({});
    const uri = `${this.backendUri }get_token/`;
    const response = await fetch(uri, opts);
    if (!response.ok) {
      // console.log('Response not ok', response);
      throw new Error(response.statusText);
    }
        const data = (await response.json()); // eslint-disable-line
    this.processResponse(data);
  }

  postHeader(payload) {
    const header = {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(payload),
    };
    header.headers = {
      'Content-Type': 'application/json',
    };
    if ('' !== this.csrfToken) {
      header.headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.csrfToken,
      };
    }
    return header;
  }

  processResponse(data) {
    // console.log('Tokens', JSON.stringify(data, null, '  '));
    this.csrfToken = data.csrf_token;
    this.client.setToken(data.ws_token);
    data.channels.forEach(c => {
      this.channels.add(c);
    });
  }

  processRawMessage(message) {
    this.tempMsg = undefined;
    try {
      this.tempMsg = new Message(message);
    } catch (e) {
      throw new Error(`Can not process message ${message} ${e}`);
    }
    return this.tempMsg;
  }
}
