<template lang='pug'>
  url-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initial"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="productsResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      v-slot="paginatedProps"
    )
      controller(
        ref="controller"
        v-bind="paginatedProps",
        :label='label',
        :sortlist='default_sort'
      )
        template(#controller="scope")
          slot(name="controller", v-bind="scope")
        slot
</template>

<script>

import { friendlyUrlParser } from '@resource/parser';

import { forumTopicsListAjax } from '@api/forum.service'

import Controller from './Controller'

export default {
  components: { Controller },
  props: {
    initial: {
    },
    baseUrl: {},
    productsResource: {
      default: () => forumTopicsListAjax,
    },
    needSorting: {},
    needSearch: {},
    label: {},
  },
  data() {
    return {
      default_sort: [
        {
          title: this._('От дешевых к дорогим'),
          key: 'price',
        },
        {
          title: this._('От дорогих к дешевым'),
          key: '-price',
        },
        {
          title: this._('Популярные'),
          key: '-rating',
        },
        {
          title: this._('Новинки'),
          key: '-date',
        },
        {
          title: this._('Акционные'),
          key: '-sale',
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        label: this.label,
      }
    },
    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser()

      return {
        ...rest,
        filters: data,
        label: this.label,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
