import { subscribe, unsubscribe } from '../subscribe';

export default {
  typerHandlers: [],

  watch: {
    typer: {
      immediate: true,
      handler(value, old) {
        if (value !== old) {
          this.unsubscribeTyper(old);
          this.subscribeTyper(value);
        }
      },
    },
  },

  activated() {
    this.subscribeTyper();
  },

  deactivated() {
    this.unsubscribeTyper();
  },

  beforeDestroy() {
    this.unsubscribeTyper();
  },

  methods: {
    subscribeTyper(typer = this.typer) {
      if (!typer) return;

      this.$options.typerHandlers = subscribe(
        typer.emitter, this.$options.typerHandlers, this
      );
    },

    unsubscribeTyper(typer = this.typer) {
      if (!typer) return;

      unsubscribe(typer.emitter, this.$options.typerHandlers);
    },
  },
};
