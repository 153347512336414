<template lang="pug">
  include /mixins
  +b.topic-template
    slot(name="title")
    slot(name="description")

</template>

<script>
export default {
  props: ['info'],
  data() {
    return {
    }
  },
  mounted() {
    console.log(this.info);
  },
  methods: {
  },
}
</script>
