/**
 *
 * @param {string} id id of element
 * @param {number} left left position
 * @param {number} top top position
 * @param {*} mouseX cursor positon-x
 * @param {*} mouseY cursor positon-x
 * @param {number} speed el speed
 */
function mouseParallax(obj, mouseX, mouseY, speed) {
  const parentObj = obj.parentNode;
  const containerWidth = parentObj.getBoundingClientRect().width
  const containerHeight = parentObj.getBoundingClientRect().height || 600
  obj.style.setProperty('--left', `${(0 - ((mouseX / containerWidth) * speed) - (speed / 2) + speed)}px`)
  obj.style.setProperty('--top', `${(0 - ((mouseY / containerHeight) * speed) - (speed / 2) + speed)}px`)
}

function randomSpeed(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}

const NONE_TOUCH_POINTS = 0

export const parallaxView = () => {
  if (
    navigator.maxTouchPoints > NONE_TOUCH_POINTS ||
    navigator.msMaxTouchPoints > NONE_TOUCH_POINTS
  ) return
  // parent el
  const parallaxBox = document.getElementById('parallax-box')
  if (!parallaxBox) return

  // set all your el`s #id
  const elements = [...document.getElementsByClassName('parallaxEl')]

  const speeds = [20, 19, 18, 17, 16, 15]

  // check window width . It`s not available on mobile devices
  parallaxBox.addEventListener('mousemove', event => {
    event = event || window.event

    const x = event.clientX - parallaxBox.offsetLeft
    const y = event.clientY - parallaxBox.offsetTop

    elements.forEach(nodeEl => {
      // left and top position for every element
      mouseParallax(nodeEl, x, y, randomSpeed(speeds))
    })
  })
  // window.addEventListener('scroll', event => {
  //   console.log(event);
  //   event = event || window.event

  //   const x = window.pageXOffset
  //   const y = window.pageYOffset
  //   elements.forEach(nodeEl => {
  //     // left and top position for every element
  //     mouseParallax(nodeEl, x, y, randomSpeed(speeds))
  //   })
  // })
}
