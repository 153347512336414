<template lang="pug">
include /mixins
+b.VALIDATION-OBSERVER(
        ref="validator"
        v-slot="{ valid }",
        tag='form'
        class="create-topic-form"
      )
        row(
          space="none"
        )
          cell(
            v-if="info"
            cols="12"
          )
            +b.topic-update-title {{_("Редактирование")}}
          cell(
            cols="12"
          )
            //- +b.H2.create-topic__title(
            //-   contenteditable="true",
            //-   :value="form.topic"
            //-   @input="e => form.topic = e.target.innerText"
            //- ) {{ _(" Название темы") }}
            d-control-input(
              :input-label='_(" Название темы")'
              vid='title'
              :placeholder='_(" Название темы")'
              rules='required'
              ref='title'
              type='text'
              v-model='form.title'
              v-bem:d-control-input="{'variant': 'ligth-label'}"
            )
          //- cell(cols="12")
          //-   +b.create-topic__date {{ currentDate }} {{form.title.length}}
          cell(
            v-for='field in fields'
            :cols='field.cols'
            :key='field.name'
          )
            tag(
              :class="field.tag"
              :tag='field.tag'
              v-model='form[field.name]'
              :input-label='field.title',
              :rules='field.rules'
              v-bind='field.props'
              :name='field.name'
              :type='field.type'
            )
        +b.BUTTON.create-topic__submit.btn.btn-red(type='submit' @click.prevent="prepareData(valid)") {{ info ? _("Сохранить") : _("Опубликовать")}}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

import {
  topicCreateResource,
  topicUpdateResource,
} from '@api/forum.service.js';

export default {
  mixins: [FormMixin],
  props: {
    slug: {
      type: String,
      required: true,
    },
    info: {
      type: Object,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      // currentDate: new Date().toLocaleString('ru', { year: 'numeric', month: 'numeric', day: 'numeric' }),
      form: {
        title: '',
        description: '',
      },
      nonFieldErrors: '',
      name: 'More',
      fields: [
        {
          cols: '12',
          title: this._('Описание'),
          rules: 'required',
          type: 'text',
          name: 'description',
          tag: 'd-control-editor',
          props: {
            rows: 8,
            class: 'editor',
            options: {
              placeholder: this._('Описание*'),
              theme: 'snow',
            },
          },
        },
      ],
    };
  },
  mounted() {
    this.form.title = this.info?.title
    this.form.description = this.info?.desc
  },
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send(data) {
      if (this.info) {
        return topicUpdateResource.execute({ id: this.id }, data)
          .then(result => {
            window.location.pathname = result.data.item.redirect;
          });
      }
      return topicCreateResource.execute({ slug: this.slug }, data)
        .then(result => {
          window.location.pathname = result.data.item.redirect;
        });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
