/* eslint-disable */
import BemComponentMixin from '../../utils/bem/BemComponentMixin';
import Message from '../Message/Message';

/**
 * Displays a group of messages from one author.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsChatMessagesGroup',
  mixins: [BemComponentMixin],

  bem: {
    block: 'wds-chat-message-group',
    modifierProps: ['alignment', 'devoted'],
  },

  components: {
    Message,
  },

  props: {
    /**
     * Message list to display.
     */
    messages: {
      type: Array,
      required: true,
    },

    /**
     * Messages author.
     */
    author: {
      type: Object,
      required: true,
    },

    /**
     * Aligns group to left or right bound of the conversation body.
     */
    alignment: {
      type: String,
      default: 'left',
    },

    /**
     * Devotes messages group to self or foreign user.
     */
    devoted: {
      type: String,
      default: 'foreign',
    },
  },

  methods: {
    getBounds(index, length) {
      const values = [];
      const last = length - 1;

      if (0 === index) {
        values.push('start');
      }

      if (index === last) {
        values.push('end');
      }

      if (0 === values.length) {
        values.push('inner');
      }

      return values;
    },

    getBoundClass(className, index, length) {
      return this.getBounds(index, length).map(x => `${className}--bound_${x}`);
    },
  },
};
