<template lang="pug">
include /mixins
+b.FORM.forum-search(
  @submit.prevent="update"
)
  +e.INPUT.input(
    :placeholder="_('Введите название темы и прочее')",
    @input="debounceAction"
    v-model="internal",
    type="text"
  )
  +e.close(
    v-if="internal && internal.length"
    @click.prevent="clear"
  )
    +b.I.icon-close
  +e.BUTTON.button(
    type="button"
    @click="update"
    :aria-label="_('Search')"
  )
</template>

<script>
import { debounce } from '@utils/helpers'

export default {
  props: {
    filters: {},
  },
  data() {
    return {
      internal: this.filters?.filters?.search && this.filters?.filters?.search[0] || '',
    };
  },
  created() {
    const delay = 500;
    this.debounceAction = debounce(this.handleInput, delay);
  },
  methods: {
    update() {
      this.$emit('update:filters', { ...this.filters, filters: { search: this.internal } })
    },
    handleInput() {
      this.update()
    },
    clear() {
      this.internal = ''
      this.handleInput()
    },
  },
};
</script>
