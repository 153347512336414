const LOADING_KEY = 'loading_ad390sd0gsdf9sf';

/**
 * @mixin
 */
export default {
  data() {
    return {
      // Dunno why, but vue do not recognize `$`(dollar sign).
      // Because loading property is internal it's not a problem to add
      // some weird key to it.
      [LOADING_KEY]: 0,
      isLoading: false,
    };
  },

  methods: {
    startLoading() {
      if (0 === this[LOADING_KEY]) {
        this.isLoading = true;
        /**
         * Loading started event.
         *
         * @event loading-started
         * @type {undefined}
         */
        this.$emit('loading-started');
      }

      this[LOADING_KEY]++;
    },

    $load(promise) {
      this.startLoading();
      return promise.finally(result => {
        this.stopLoading();
        return result;
      });
    },

    stopLoading() {
      this[LOADING_KEY]--;

      if (0 === this[LOADING_KEY]) {
        this.isLoading = false;
        /**
         * Loading finished event.
         *
         * @event loading-finished
         * @type {undefined}
         */
        this.$emit('loading-finished');
      }
    },
  },
};
