import Ui from './UiElements'
import Cabinet from './Cabinet'
import ControlsComponents from './Controls'
import Triggers from './Triggers'
import Utils from './Utils'
import Interaction from './Interaction'
import installPagination from './Pagination'
import General from './General'

export function install(Vue) {
  Vue.use(Ui)
  Vue.use(ControlsComponents)
  Vue.use(Triggers)
  Vue.use(Cabinet)
  Vue.use(Utils)
  Vue.use(installPagination)
  Vue.use(Interaction)
  Vue.use(General)
}

export default { install }
