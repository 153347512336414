<template lang="pug">
  include /mixins
  +b.A.auth-item__link.log-out-link(
    href='#'
    @click.prevent="logOut()"
    ) {{_("Выйти")}}
</template>

<script>
import {
  logoutResource,
} from '@api/auth.service.js';

export default {
  name: 'LogoutTrigger',
  data() {
    return {
    };
  },
  methods: {
    logOut() {
      return logoutResource.execute({}).then(res => {
        if (202 === res.code) {
          window.location.pathname = res.data.item.redirect;
        }
      });
    },
  },
};
</script>
