<template lang="pug">
include /mixins
+b.cabinet-side-wrapper
  +b.g-container--variant_default
    +b.SECTION.cabinet-side
      +e.hero
        +e.hero-img
          +b.ds-avatar.--bg_none.--appearance_rounded.--size_avatar-mini(

          )
            +e.IMG.image(
              v-if="userData.avatar"
              :src='userData.avatar'
            )

            +e.label(v-else) {{userData.username? userData.username[0] : ''}}
        +e.hero-info
          +e.hero-name {{userData.username? userData.username : ''}}
          +e.balance
            +e.balance-text {{_('Баланс')}}:
            +e.balance-num
              +e.balance-currency $
              +e.P.balance-count {{userData.balance? userData.balance : ''}}
      +e.NAV.nav
        +e.UL.list
          +e.LI.item(v-for="(item, index) in sidebarMenu" :key="item.index")
            template(v-if="item.logOut")
              +e.icon(
                :class="item.icon"
              )
              +e.SPAN.link.cur-pointer(
                @click="logOut()"
                ) {{item.title}}
            template(v-else-if="item.notify")
              +e.link-wrapper
                +e.icon(
                  :class="item.icon"
                )
                +e.ROUTER-LINK.link(
                  :to="{name: item.link}"
                  ) {{item.title}}
                template(v-if="notifyCounter.count > 0")
                  +e.count-icon
                    +e.count-text {{notifyCounter.count > 10? '10+' : notifyCounter.count}}
            template(v-else-if="item.chats")
              +e.link-wrapper
                +e.icon(
                  :class="item.icon"
                )
                +e.ROUTER-LINK.link(
                  :to="{name: item.link}"
                  ) {{item.title}}
                template(v-if="chatsCounter.count > 0")
                  +e.count-icon
                    +e.count-text {{chatsCounter.count > 10? '10+' : chatsCounter.count}}
            template(v-else-if="item.contact")
              +e.link-wrapper
                +e.icon(
                  :class="item.icon"
                )
                +e.ROUTER-LINK.link(
                  :to="{name: item.link, params: {usedData: userData}}"
                  ) {{item.title}}
            template(v-else)
              +e.icon(
                :class="item.icon"
              )
              +e.ROUTER-LINK.link(
                :to="{name: item.link}"
                ) {{item.title}}
</template>
<script>
import {
  logoutResource,
} from '@api/auth.service.js';
import {
  usedDataSidebar,
} from '@api/cabinet.service.js';
import {
  PROFILE_DATA_NAME,
  USER_COURSES_DATA_NAME,
  USER_TRAININGS_LIST_NAME,
  MY_FAVORITES_LIST_NAME,
  MY_SUBSCRIPTIONS_LIST_NAME,
  MY_LIBRARY_LIST_NAME,
  MY_TOPICS_LIST_NAME,
  MY_TRANSACTIONS_LIST_NAME,
  NOTIFICATIONS_LIST_NAME,
  MESSAGES_LIST_NAME,
  CONTACT_US_NAME,
} from '../routes'

export default {
  data() {
    return {
      userData: '',
      sidebarMenu: [
        {
          title: this._('Профильные данные'),
          icon: 'icon-personal-hero',
          link: PROFILE_DATA_NAME,
        },
        {
          title: this._('Мой абонемент'),
          icon: 'icon-personal-subsription',
          link: MY_SUBSCRIPTIONS_LIST_NAME,
        },
        {
          title: this._('Мои курсы'),
          icon: 'icon-personal-courses',
          link: USER_COURSES_DATA_NAME,
        },
        {
          title: this._('Моя библиотека'),
          icon: 'icon-personal-library',
          link: MY_LIBRARY_LIST_NAME,
        },
        {
          title: this._('Мои групповые тренировки'),
          icon: 'icon-personal-trainings',
          link: USER_TRAININGS_LIST_NAME,
        },
        {
          title: this._('Мои темы'),
          icon: 'icon-comment-multiple-outline',
          link: MY_TOPICS_LIST_NAME,
        },
        {
          chats: true,
          title: this._('Сообщение'),
          icon: 'icon-personal-message',
          link: MESSAGES_LIST_NAME,
        },
        {
          notify: true,
          title: this._('Уведомления'),
          icon: 'icon-personal-alert',
          link: NOTIFICATIONS_LIST_NAME,
        },
        {
          title: this._('Подписки'),
          icon: 'icon-personal-transactions',
          link: MY_FAVORITES_LIST_NAME,
        },
        {
          title: this._('Транзакции'),
          icon: 'icon-personal-transactions',
          link: MY_TRANSACTIONS_LIST_NAME,
        },
        {
          contact: true,
          title: this._('Связаться с нами'),
          icon: 'icon-personal-contact',
          link: CONTACT_US_NAME,
        },
        {
          title: this._('Выйти'),
          icon: 'icon-personal-logout',
          link: '',
          logOut: true,
        },
      ],
    }
  },
  inject: {
    chatsCounter: {
      from: 'chatsCounter',
      default: () => ({ count: 0 }),
    },
    notifyCounter: {
      from: 'notifyCounter',
      default: () => ({ count: 0 }),
    },
  },
  watch: {
    $route() {
      this.$emit('close-sidebar')
      this.$body.classList.remove('is-blocked')
    },
  },
  mounted() {
    this.loadUserData()
  },
  methods: {
    logOut() {
      return logoutResource.execute({}, this.form).then(res => {
        if (202 === res.code) {
          window.location.pathname = res.data.item.redirect;
        }
      });
    },
    loadUserData() {
      return usedDataSidebar.execute({}).then(res => {
        if (200 === res.code) {
          this.userData = res.data.item
        }
      });
    },
  },
}
</script>
