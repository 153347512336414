/**
 * @mixin
 */
export default {
  props: {
    /**
     * Message list to display.
     */
    messages: {
      type: Array,
      required: true,
    },

    /**
     * Authors map, that massages has.
     */
    authors: {
      type: Object,
      required: true,
    },

    /**
     * Current author identifier.
     */
    author: Object,
  },
};
