/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
const EventEmitter = require('events');

export class DataFeedInterface {
  /**
   * Event emitter is used to receive chat events.
   * It emits a received events.
   *
   * @memberof DataFeedInterface
   */
  emitter = new EventEmitter()

  /**
   * Connection state indicator.
   *
   * @memberof DataFeedInterface
   */
  isOnline = true;

  /**
   * Method to receive old messages, based on passed parameters.
   *
   * @param {*} {} Filter parameters:
   *  - start: Starting bound UTC datetime timestamp on `createdAt` parameter.
   *  - end: Finishing bound UTC datetime timestamp on `createdAt` parameter.
   *  - chatId: Chat identifiers.
   *  - query: Search query.
   *  - authorId: Author identifier.
   *  - limit: Messages list limit count.
   *  - after: Messages list start cursor.
   *  - before: Messages list end cursor.
   * @returns {Promise} Resolves into list of message objects.
   * @memberof DataFeedInterface
   */
  receiveHistory({
    start, end, chatId, query, author, limit, after, before,
  } = {}) {}

  /**
   * Method to receive authors information.
   *
   * @param {*} {} Filter parameters:
   *  - authorIds: List of author identifiers.
   *  - chatId: Chat identifiers.
   *  - query: Search query.
   * @returns {Promise} Resolves into list of author objects.
   * @memberof DataFeedInterface
   */
  receiveAuthors({ authorIds, chatId, query }) {}

  /**
   * Method to receive existing chats.
   *
   * @param {*} {} Filter parameters:
   *  - start: Starting bound UTC datetime timestamp on `updatedAt` parameter.
   *  - end: Finishing bound UTC datetime timestamp on `updatedAt` parameter.
   *  - authorIds: List of author identifiers.
   *  - chatIds: List of chat identifiers.
   *  - query: Search query.
   *  - orderBy: Parameter to determine how to sort chats.
   *  - limit: Chats list limit count.
   *  - after: Charts list start cursor.
   *  - before: Charts list end cursor.
   * @returns {Promise} Resolves into list of chat objects.
   * @memberof DataFeedInterface
   */
  receiveChats({
    start, end, authorIds, chatIds, query, orderBy, limit, after, before,
  }) { }

  /**
   * Sends file to server.
   * Result fill be File descriptor object.
   *
   * @param {File} file [File object](https://developer.mozilla.org/ru/docs/Web/API/File)
   *  that must be sended.
   * @returns {EventEmitter} Emits events about file uploading progress,
   * @memberof DataFeedInterface
   */
  sendFile(file) {}

  /**
   * Sends message to chat.
   *
   * @param {Message} message Chat Message object to send. If object has
   *  id parameter provided, then message will be updated.
   * @memberof DataFeedInterface
   */
  sendMessage(message) {
    this.sendEvent('chat:message:new', message);
  }

  /**
   * Sends event.
   *
   * @param {String} type Chat Event type.
   * @param {Object} body Chat Event object to send.
   * @memberof DataFeedInterface
   */
  sendEvent(type, body) {}
}
