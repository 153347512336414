<template lang="pug">
  include /mixins
  div
    slot(
      name='btn',
      :open='() => toggleSidebar()',
      :isActive='isActive'
      :class='{active: isActive}'
    )
    transition(name='slide-fade')
      .is-blured(
        v-if='isActive'
        @click.prevent='toggleSidebar()'
      )
    transition(name='slide-right')
      +b#auth-menu(
        v-if='isActive'
        :class='{active: isActive}'
        )
        slot(
          :close='() => isActive = false'
          name='sidebar'
        )
</template>

<script>
export default {
  name: 'UiSidebar',
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.$body.classList.add('is-blocked')
      } else {
        this.$body.classList.remove('is-blocked')
      }
      this.$body.dataset.blocked = this.isActive;
    },
  },
};
</script>
