<template lang="pug">
include /mixins
div
  slot(
    name="controller"
    :filters="filters"
    @update:filters="changeFilters"
    :change-filters="changeFilters"
    :pagination="pagination",
    :label='label'
  )
  +b.general-wrapper
    +b.g-container--variant_default
      +b.SECTION.general
        catalog-content(
          :loading="loading"
          :items="result && result.data"
          :pagination="pagination"
          @update:pagination="changePagination"
        )
          slot
</template>
<script>

// import CatalogSidebar from './Sidebar'
import CatalogContent from './Content'

export default {
  components: {
    // CatalogSidebar,
    CatalogContent,
  },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'availableAttributesResource',
    'needPrices',
    'needSorting',
    'needTitle',
    'needSearch',
    'label',
    'sortlist',
  ],

  created() {
    // this.changeFilters({ ...this.filters, search: '10' })
  },

  methods: {
    search() {
      this.receive(this.parameters)
    },
  },
}
</script>
