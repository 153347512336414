<template lang="pug">
include /mixins
+b.g-row.--space_lg
  +b.g-cell.g-cols-narrow
    +b.A.header__hero.--color_light(
      :href="`${url}notifications/`"
      :class="notifyCounter.count > 0? 'header__hero--active' : ''"
      )
      +b.hero-icon.icon-personal-alert
  +b.g-cell.g-cols-narrow
    +b.A.header__hero.--color_light(
      :href="`${url}messages/`"
      :class="chatsCounter.count > 0? 'header__hero--active' : ''"
      )
      +b.hero-icon.icon-personal-message
</template>

<script>
export default {
  props: ['url'],
  name: 'NotifyButtons',
  data() {
    this.isAuth = window.IS_AUTHINTICATED
    this.langCode = window.language
    return {
      newNotifycations: '',
    };
  },
  inject: {
    chatsCounter: {
      from: 'chatsCounter',
      default: () => ({ count: 0 }),
    },
    notifyCounter: {
      from: 'notifyCounter',
      default: () => ({ count: 0 }),
    },
  },
};
</script>
