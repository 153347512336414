<template lang="pug">
include /mixins
+b.join-us
  +b.g-row
    +b.g-cell.g-cols--12
      +b.P.join-us-title {{_('Мотивационный текст в две строки!')}}
    +b.g-cell.g-cols--12
      +b.p.join-us-subtitle {{_('Присоединяйтесь к нам!')}}
    +b.g-cell.g-cols--12
      +b.BUTTON.contact-form__submit.btn.btn-red(type='submit' @click.prevent="modalOpener(RegModalConfig)") {{_('Регистрация')}}
</template>

<script>
import { modalOpener } from '@utils/helpers'

const ReristerModal = () => import('../Modals/Auth/Register');

export default {
  data() {
    return {
      RegModalConfig: {
        props: {
          isComponent: ReristerModal,
        },
        // classes: ['modal-wrapper--theme_dark'],
        config: {
          width: '345',
          height: 'auto',
          adaptive: true,
          scrollable: true,
        },
      },
    };
  },
  methods: {
    modalOpener,
  },
};
</script>
