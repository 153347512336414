/* eslint-disable */
import FeedEventsHandlerMixin from '../utils/mixins/FeedEventsHandlerMixin';

export default {
  mixins: [FeedEventsHandlerMixin],

  feedHandlers: [
    {
      event: 'chat:room:updated',
      handler(event) {
        this.setChat(event.body);
      },
    },
    {
      event: 'chat:room:new',
      handler(event) {
        this.setChat(event.body);
      },
    },
    {
      event: 'chat:author:updated',
      handler(event) {
        this.setAuthor(event.body);
      },
    },
  ],

  data() {
    return {
      feed: null,
      chats: {},
      authors: {},
    };
  },

  methods: {
    bind(feed) {
      this.feed = feed;
    },

    unbind() {
      this.feed = null;
    },

    setChat(chat) {
      this.chats[chat.id || chat.pk] = chat;
      if (!chat.authors) chat.authors = chat.members
      Object.values(chat.authors).forEach(this.setAuthor);
      this.reassign();
    },

    setAuthor(author) {
      this.authors[author.id] = author;

      Object.values(this.chats).forEach(chat => {
        if (chat.authors[author.id]) {
          chat.authors[author.id] = author;
        }
      });
      this.reassign();
    },

    reassign() {
      // FXME: This thing exists because of vue does not detects
      // changes inside the objects.
      this.chats = { ...this.chats };
      this.authors = { ...this.authors };
    },
  },
};
